import React, { Fragment, Component } from "react";
import { Header, Image, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import imageUrl from "../../assets/img/ul-logo.png";
import { Link, withRouter } from "react-router-dom";

import { Dropdown, Segment, Button } from "semantic-ui-react";

class HeaderBlock extends Component {
  state = { initialName: "", showIcon: false };

  componentDidMount() {}

  handleChange(value) {}
  onClickUser() {
    this.props.history.push("/home/user-profile");
  }

  render() {
    const { initialName, showIcon } = this.state;
    return (
      <Fragment>
        <Segment clearing className="com-header-container" basic>
          <Header as="h3" floated="left" className="header">
            <Image
              className="image"
              src={imageUrl}
              size="small"
              as={Link}
              to="/home"
            />
            Production Volume Reporting
          </Header>
          {false ? (
            <Icon
              className="initial"
              size="large"
              circular
              inverted
              color="red"
              link
              onClick={this.onClickUser.bind(this)}
            >
              {initialName}
            </Icon>
          ) : null}
          {/* <Dropdown
            button
            className="icon language"
            labeled
            icon="world"
            options={LANGUAGE_OPTIONS}
            onChange={(e, { value }) => {
              this.handleChange(value);
            }}
            text={this.props.text}
            value={this.props.value}
          /> */}
        </Segment>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(withRouter(HeaderBlock));
