import * as types from "./callback.type";

const initialState = {
  hasError: false,
  message: null,
  data: null,
  isSuccess: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.VALIDATE_AUTHENTICATION_CODE_REQUEST:
      return {
        ...state,
        isSuccess: false
      };
    case types.VALIDATE_AUTHENTICATION_CODE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        data: action.payload
      };
    case types.VALIDATE_AUTHENTICATION_CODE_FAILURE:
      return {
        ...state,
        isSuccess: false,
        data: null
      };
    default:
      return state;
  }
};

export default authReducer;
