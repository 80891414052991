import React, { Component, Fragment } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  withRouter
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  Divider,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Label,
  Accordion
} from "semantic-ui-react";
import History from "../../views/history/history";
import UserMaintenance from "../../views/user-maintenance/user-maintenance";
import RoleMaintenance from "../../views/roles-maintenance/roles-maintenance";
import Logout from "../../views/logout/logout";
import WelcomePage from "./welcome-label";
import LocationSelection from "../../views/location-selection/location-selection";
import HomePage from "./welcome-label";
import routes from "../../nav/routes";
class SidebarMenu extends Component {
  state = {};

  render() {
    const { activeIndex } = this.state;
    let navRoutes = routes.filter((r) => r.sidebar);
    let otherRoutes = routes.filter((r) => !r.sidebar);

    return (
      <Fragment>
        <Sidebar.Pushable className="com-sidebar-container">
          <Sidebar as={Menu} animation="uncover" vertical visible>
            {navRoutes &&
              navRoutes.map((route, idx) => {
                return (
                  // <Menu.Item
                  //   key={idx}
                  //   name={route.name}
                  //   as={Link}
                  //   to={route.to}
                  // >
                  //   <Icon name={route.icon} />
                  //   {route.value}
                  // </Menu.Item>
                  <Menu.Item
                    key={idx}
                    name={route.name}
                    as={Link}
                    to={route.to}
                  >
                    <Icon name={route.icon} className="item-icon" />
                    {route.value}
                  </Menu.Item>
                );
              })}
            <Divider className="com-sidebar-divider" fitted />

            <Logout />
          </Sidebar>
          <Sidebar.Pusher className="pusher">
            <Route
              render={({ location }) => (
                <TransitionGroup>
                  <CSSTransition
                    key={location.key}
                    timeout={300}
                    classNames="fade"
                  >
                    <Switch location={location}>
                      {navRoutes &&
                        navRoutes.map((route, idx) => {
                          return (
                            <Route
                              exact
                              key={idx}
                              path={route.to}
                              component={route.component}
                            />
                          );
                        })}
                      {otherRoutes &&
                        otherRoutes.map((route, idx) => {
                          return (
                            <Route
                              exact
                              key={idx}
                              path={route.to}
                              component={route.component}
                            />
                          );
                        })}
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              )}
            />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Fragment>
    );
  }
}

export default SidebarMenu;
