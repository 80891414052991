export const GET_MANUFACTURERS_REQUEST = "GET_MANUFACTURERS_REQUEST";
export const GET_MANUFACTURERS_FAILURE = "GET_MANUFACTURERS_FAILURE";
export const GET_MANUFACTURERS_SUCCESS = "GET_MANUFACTURERS_SUCCESS";

export const GET_MANUFACTURERS_BY_ID_REQUEST =
  "GET_MANUFACTURERS_BY_ID_REQUEST";
export const GET_MANUFACTURERS_BY_ID_FAILURE =
  "GET_MANUFACTURERS_BY_ID_FAILURE";
export const GET_MANUFACTURERS_BY_ID_SUCCESS =
  "GET_MANUFACTURERS_BY_ID_SUCCESS";

export const SET_CURRENT_MANUFACTURER = "SET_CURRENT_MANUFACTURER";
