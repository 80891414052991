import React, { Component, Fragment } from "react";

import { Redirect, BrowserRouter as Router } from "react-router-dom";
import HeaderBlock from "../../components/header/header-block";
import SidebarMenu from "../../components/sidebar/sidebar-menu";
const API_ROOT = `${process.env.REACT_APP_API_ROOT}`;

class Welcome extends Component {
  state = {};
  render() {
    document.getElementById("root").style.backgroundColor = "#e2e2e2";

    const userId = localStorage.getItem("userId");

    if (!userId) {
      var externalAuthURL = `${API_ROOT}auth/challenge`;

      var a = document.createElement("a");
      a.style = "display: none";
      a.href = externalAuthURL;
      a.target = "_self";
      a.click();
      window.URL.revokeObjectURL(externalAuthURL);
    }

    return (
      <Fragment>
        <Router>
          <HeaderBlock />
          <SidebarMenu />
        </Router>
      </Fragment>
    );
  }
}

export default Welcome;
