import axios from "axios";
import { Common } from "../helpers/common.helper";
import IdentityProvider from "../enums/identity-provider";

const API_ROOT = `${process.env.REACT_APP_API_ROOT}`;
const API_BASE = `${process.env.REACT_APP_API_BASE}`;
const IDENTITY_PROVIDER = `${process.env.REACT_APP_IDENTITY_PROVIDER}`;

const handleErrors = () => {};

const handleResponse = (res) => {
  return res && res.data;
};

const beforeRequest = async (config) => {
  return config;
};

const createApi = () => {
  const api = createDefaultApi();
  if (IDENTITY_PROVIDER == IdentityProvider.B2c) {
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const refreshToken = localStorage.getItem("refreshToken");

        if (error.response.status !== 401) {
          return Promise.reject(error);
        } else {
          axios.interceptors.response.eject(api.interceptors);

          return axios
            .post(`${API_ROOT}auth/refresh-token`, {
              refreshToken: JSON.parse(refreshToken)
            })
            .then((response) => {
              if (response.data.isSuccessful) {
                localStorage.setItem(
                  "b2cToken",
                  JSON.stringify(response.data.data.idToken)
                );
                localStorage.setItem(
                  "token",
                  JSON.stringify(response.data.data.authenticatedUserToken)
                );
                localStorage.setItem(
                  "refreshToken",
                  JSON.stringify(response.data.data.refreshToken)
                );

                error.response.config.headers["B2CHeader"] =
                  response.data.data.idToken;
                error.response.config.headers[
                  "Authorization"
                ] = `Bearer ${response.data.data.authenticatedUserToken}`;

                return axios(error.response.config);
              } else {
                localStorage.clear();
                var externalLogoutURL = `${API_BASE}auth/logout`;
                window.top.location.href = externalLogoutURL;
                return Promise.reject(error);
              }
            })
            .catch((error) => {
              return Promise.reject(error);
            });
        }
      }
    );
  } else if (IDENTITY_PROVIDER == IdentityProvider.Iam) {
    const expiration = Common.getTokenExpirationIam();

    if (expiration) {
      var isExpired = Date.now() >= expiration * 1000;

      if (isExpired) {
        localStorage.clear();
        var externalLogoutURL = `${API_BASE}auth/logout`;
        window.top.location.href = externalLogoutURL;
      }
    }
  }

  return api;
};

const createDefaultApi = () => {
  const token = localStorage.getItem("token");
  const b2cToken = localStorage.getItem("b2cToken");

  let options = {
    baseURL: API_ROOT,
    timeout: 30000
  };

  if (token !== null && token.trim().length > 0)
    options.headers = {
      Authorization: `Bearer ${JSON.parse(token)}`,
      B2CHeader: JSON.parse(b2cToken)
    };

  const api = axios.create(options);
  api.interceptors.request.use(beforeRequest);
  return api;
};

export const requests = {
  get: (url) =>
    createApi()
      .get(`${API_ROOT}${url}`)
      .then(handleResponse)
      .catch(handleErrors),
  post: (url, data) =>
    createApi().post(`${API_ROOT}${url}`, data).then(handleResponse),
  put: (url, data) =>
    createApi()
      .put(`${API_ROOT}${url}`, data)
      .then(handleResponse)
      .catch(handleErrors),
  delete: (url) =>
    createApi()
      .delete(`${API_ROOT}${url}`)
      .then(handleResponse)
      .catch(handleErrors),
  upload: (url, formData) => {
    let options = {
      baseURL: API_ROOT,
      timeout: 30000
    };

    const token = localStorage.getItem("token");
    if (token !== null && token.trim().length > 0)
      options.headers = { Authorization: `Bearer ${JSON.parse(token)}` };

    const api = axios.create(options);
    api.interceptors.request.use(beforeRequest);
    return api
      .post(url, formData, {
        headers: { "content-type": "multipart/form-data" }
      })
      .then(handleResponse)
      .catch(handleErrors);
  }
};
