import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers/root-reducer";
// import memberReducer from '../reducers/members'
// const loggerMiddleware = createLogger();
import checkTokenExpirationMiddleware from "../middleware/checkTokenExpirationMiddleware";

export default function configureStore(initialState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // options like actionSanitizer, stateSanitizer
      })
    : compose;

  const enhancer = composeEnhancers(
    applyMiddleware(thunk, checkTokenExpirationMiddleware)
  );

  return createStore(
    rootReducer,
    // memberReducer,
    initialState,
    enhancer
  );
}
