import React, { Component } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import * as customerActions from "../location-selection/location-selection.action";
import {
  Accordion,
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  List,
  Menu,
  Message,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { convertUnixTimeToDate } from "../../helpers/common.helper";

class PsnInfo extends Component {
  state = {
    activeIndex: 0,
    activeItem: "account",
    isSuccessGetCustomer: false,
    filterData: null,
  };

  componentDidMount() {}

  render() {
    const { custDetails, usageReport } = this.props;

    const customer = custDetails.customerDetails || {};
    const prodFile = usageReport.selectedProductFile;
    const prodInfo = custDetails.productFileInfo;

    return (
      <Fragment>
        <Container fluid>
          <Grid divided="vertically">
            <Grid.Row>
              <Grid.Column>
                <Segment>
                  <Grid divided>
                    <Grid.Row>
                      <Grid.Column>
                        <h1>Reported Usage</h1>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width="5">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <h4>REPORTED BY</h4>
                              </td>
                            </tr>
                            <tr>
                              <td>{customer.customerName}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Grid.Column>
                      <Grid.Column width="3">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <h4>Party Site Number (PSN)</h4>
                              </td>
                            </tr>
                            <tr>
                              <td>{customer.psn}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Grid.Column>
                      <Grid.Column width="3">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <h4>ADDRESS</h4>
                              </td>
                            </tr>
                            <tr>
                              <td>{customer.addressLine1}</td>
                            </tr>
                            <tr>
                              <td>
                                {customer.city}, {customer.state}{" "}
                                {customer.postalCode}
                              </td>
                            </tr>
                            <tr>
                              <td>{customer.country}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Grid.Column>
                      <Grid.Column width="5">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <h4>Product Type: </h4>
                              </td>
                              <td>{prodFile.productType}</td>
                            </tr>
                            <tr>
                              <td>
                                <h4>File #: </h4>
                              </td>
                              <td>{prodFile.fileNumber}</td>
                            </tr>
                            <tr>
                              <td>
                                <h4>Volume: </h4>
                              </td>
                              <td>{prodFile.volume}</td>
                            </tr>
                            <tr>
                              <td>
                                <h4>CCN: </h4>
                              </td>
                              <td>{prodFile.ccn}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  usageReport: state.usageReport,
  locationSelection: state.locationSelection,
  selectedProductFile: state.selectedProductFile,
  productInfo: state.productFileInfo,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PsnInfo);
