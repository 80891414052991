import { randomBytes, createHash } from "crypto-browserify";
import jwtDecode from "jwt-decode";

const REDIRECT_URL = `${process.env.REACT_APP_REDIRECT_URL}`;
const AUTH_URL = `${process.env.REACT_APP_AUTH_URL}`;
const POLICY_NAME = `${process.env.REACT_APP_B2C_POLICY_NAME}`;
const CLIENT_ID = `${process.env.REACT_APP_B2C_CLIENT_ID}`;
const LOGOUT_URL = `${process.env.REACT_APP_LOGOUT_URL}`;
const WEB_URL = `${process.env.REACT_APP_WEB_URL}`;

const getLocalStorage = (data) => {
  const raw = localStorage.getItem(data);
  return raw ? JSON.parse(raw) : {};
};

export const convertUnixTimeToDate = (value) => {
  var date = new Date(value);
  return date.toLocaleString();
};

function base64URLEncode(str) {
  return str
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
}

function sha256(buffer) {
  return createHash("sha256").update(buffer).digest();
}

export const Common = {
  convertUnixTimeToDate: (value) => {
    var date = new Date(value);
    return date.toLocaleString();
  },
  getTokenExpiration: () => {
    let token = getLocalStorage("b2cToken");
    let expiration = null;
    if (!Object.IsEmpty(token)) {
      let value = jwtDecode(token);
      expiration = JSON.parse(value.exp);
    }

    return expiration;
  },

  getTokenExpirationIam: () => {
    let token = getLocalStorage("token");
    let expiration = null;
    if (!Object.IsEmpty(token)) {
      let value = jwtDecode(token);
      expiration = JSON.parse(value.exp);
    }

    return expiration;
  },

  getRefreshToken: () => {
    let refreshToken = getLocalStorage("refreshToken");
    return refreshToken;
  },

  toggleLogin: () => {
    var codeVerifier = base64URLEncode(randomBytes(32));
    var challenge = base64URLEncode(sha256(codeVerifier));
    localStorage.setItem("codeVerifier", codeVerifier);
    var redirectUri = encodeURIComponent(REDIRECT_URL);
    var externalAuthURL = AUTH_URL.replace("--CODE_CHALLENGE--", challenge)
      .replace("--REDIRECT_URI--", redirectUri)
      .replace("--POLICY_NAME--", POLICY_NAME)
      .replace("--CLIENT_ID--", CLIENT_ID);

    var a = document.createElement("a");
    a.style = "display: none";
    a.href = externalAuthURL;
    a.target = "_self";
    a.click();
  },

  toggleSilentLogin: () => {
    var codeVerifier = base64URLEncode(randomBytes(32));
    var challenge = base64URLEncode(sha256(codeVerifier));
    localStorage.setItem("codeVerifier", codeVerifier);
    var redirectUri = encodeURIComponent(REDIRECT_URL);
    var externalAuthURL = AUTH_URL.replace("--CODE_CHALLENGE--", challenge)
      .replace("--REDIRECT_URI--", redirectUri)
      .replace("--POLICY_NAME--", POLICY_NAME)
      .replace("--CLIENT_ID--", CLIENT_ID)
      .replace("prompt=login", "prompt=none");

    var a = document.createElement("a");
    a.style = "display: none";
    a.href = externalAuthURL;
    a.target = "_self";
    a.click();
  },

  getB2cToken: () => {
    return getLocalStorage("b2cToken");
  },
  getManufacturerId: () => {
    return getLocalStorage("selectedManufacturerId");
  },
  toggleLogout: (b2cToken) => {
    var logoutUrl = LOGOUT_URL.replace("--POLICY_NAME--", POLICY_NAME)
      .replace("--TOKEN_HINT--", b2cToken)
      .replace("--REDIRECT_URI--", WEB_URL);

    var a = document.createElement("a");
    a.style = "display: none";
    a.href = logoutUrl;
    a.target = "_self";
    a.click();
  }
};
