import update from "immutability-helper";
import merge from "deepmerge";
import _ from "lodash";

export const validate = (field, name, ...validations) => {
  return (state, fieldToValidate) => {
    for (let v of validations) {
      let func = v(_.get(state, field), state);

      if (fieldToValidate) {
        if (func && field === fieldToValidate)
          return _.set({}, field, func(name));

        continue;
      }

      if (func) return _.set({}, field, func(name));
    }

    return null;
  };
};

export const createValidation = (state, validators) => {
  return validators.reduce((memo, validator) => {
    return update(memo, { $merge: _.merge(memo, validator(state)) });
  }, {});
};

export const createValidationField = (state, validators, field) => {
  return validators.reduce((memo, validator) => {
    return update(memo, { $merge: _.merge(memo, validator(state, field)) });
  }, {});
};

export const validateField = (rules, form, name, value) => {
  let newState = update(form, {
    $merge: _.set(form, name, value),
  });

  _.unset(form.validationErrors, name);
  newState.validationErrors = update(form.validationErrors, {
    $merge: merge(
      form.validationErrors,
      createValidationField(newState, rules, name)
    ),
  });

  return newState;
};

export const validateSubFormField = (rules, subForm, name, value) => {
  let newState = update(subForm, {
    $merge: _.set(subForm, name, value),
  });

  _.unset(subForm.validationErrors, name);

  newState.validationErrors = update(subForm.validationErrors, {
    $merge: merge(
      subForm.validationErrors,
      createValidationField(newState, rules, name)
    ),
  });

  return newState;
};

export const getError = (field, form, data) => {
  // debugger;
  let error = _.get(form.validationErrors, field) || data.message; // API CommandResult return
  // TODO unset error from fluent validation
  // Errors from fluent validation's API

  if (!error && data.errors) {
    if (_.has(data.errors, field.toCapitalize()))
      error = _.get(data.errors, field.toCapitalize())[0]; // Always get the first error if multiple
  }

  return error;
};
