import * as types from "./reported-usage.type";

let initialState = {
  inspectionReports: null,
  isLoadingReport: false,
  isSuccessReport: false,
  isLoadingCreateUsageReport: false,
  isSuccessCreateUsageReport: false,
  isLoadingDownloadReport: false,
  products: null,
  productFiles: [],
  selectedFiles: [],
  isLoadingProducts: false,
  isSuccessProducts: false,
  productInfo: null,
  productInfoByPsn: null,
  isLoadingProductInfo: false,
  isSuccessProductInfo: false,
  lastTransaction: null,
  downloadReports: null,
  hasDownloadReports: false,
  currentPage: 1,
  pageSize: 5,
  form: {
    startDate: null,
    endDate: null,
    showErrors: false,
    validationErrors: {}
  },
  selectedProductFile: null,
  isSelectedAll: false,
  filterProduct: [],
  filterText: "",
  productIds: ""
};

const reportedUsageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INSPECTION_REPORT_REQUEST:
      return {
        ...state,
        isLoadingReport: true,
        isSuccessReport: false
      };
    case types.GET_INSPECTION_REPORT_SUCCESS:
      var result = Object.assign({}, state, {
        inspectionReports: action.data,
        isLoadingReport: false,
        isSuccessReport: true
      });
      return result;
    case types.GET_INSPECTION_REPORT_FAILURE:
      var result = Object.assign({}, state, {
        inspectionReports: null,
        isLoadingReport: false,
        isSuccessReport: false
      });
      return result;
    case types.GET_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoadingProducts: true,
        isSuccessProducts: false,
        productFiles: []
      };
    case types.GET_PRODUCTS_SUCCESS:
      var result = Object.assign({}, state, {
        products: action.data,
        isLoadingProducts: false,
        isSuccessProducts: true
      });
      var displayNames = result.products.map((p) => {
        return p.displayName;
      });

      displayNames.forEach((displayName) => {
        result.productFiles.push({
          text: displayNames,
          fileVolumes: result.products.filter(
            (p) => p.displayName == displayName
          )
        });
      });

      return result;
    case types.GET_PRODUCTS_FAILURE:
      var result = Object.assign({}, state, {
        products: null,
        productFiles: [],
        isLoadingProducts: false,
        isSuccessProducts: false
      });
      return result;
    case types.GET_PRODUCT_INFO_REQUEST:
      return {
        ...state,
        isLoadingProductInfo: true,
        isSuccessProductInfo: false
      };
    case types.GET_PRODUCT_INFO_SUCCESS:
      var result = Object.assign({}, state, {
        productInfo: action.data,
        isLoadingProductInfo: false,
        isSuccessProductInfo: true
      });
      return result;
    case types.GET_PRODUCT_INFO_FAILURE:
      var result = Object.assign({}, state, {
        productInfo: null,
        isLoadingProductInfo: false,
        isSuccessProductInfo: false
      });
      return result;
    case types.GET_PRODUCT_INFO_BY_PSN_REQUEST:
      return {
        ...state,
        productInfoByPsn: null
      };
    case types.GET_PRODUCT_INFO_BY_PSN_SUCCESS:
      var result = Object.assign({}, state, {
        productInfoByPsn: action.data
      });
      return result;
    case types.GET_PRODUCT_INFO_BY_PSN_FAILURE:
      var result = Object.assign({}, state, {
        productInfoByPsn: null
      });
      return result;
    case types.PRODUCT_FILE_INFO_BY_ID_REQUEST:
      return {
        ...state,
        productFileInfo: null
      };
    case types.PRODUCT_FILE_INFO_BY_ID_SUCCESS:
      var result = Object.assign({}, state, {
        productFileInfo: action.data,
        filterProduct: action.data
      });
      return result;
    case types.PRODUCT_FILE_INFO_BY_ID_FAILURE:
      var result = Object.assign({}, state, {
        productFileInfo: null
      });
      return result;
    case types.CREATE_INSPECTION_REPORT_REQUEST:
      return {
        ...state,
        isLoadingCreateUsageReport: true,
        isSuccessCreateUsageReport: false
      };
    case types.CREATE_INSPECTION_REPORT_SUCCESS:
      var result = Object.assign({}, state, {
        isLoadingCreateUsageReport: false,
        isSuccessCreateUsageReport: action.data.isSuccessful
      });
      return result;
    case types.CREATE_INSPECTION_REPORT_FAILURE:
      var result = Object.assign({}, state, {
        isLoadingCreateUsageReport: false,
        isSuccessCreateUsageReport: false
      });
      return result;
    case types.GET_INSPECTION_LAST_TRANSACTION_REQUEST:
      return {
        ...state,
        lastTransaction: null
      };
    case types.GET_INSPECTION_LAST_TRANSACTION_SUCCESS:
      var result = Object.assign({}, state, {
        lastTransaction: action.data
      });
      return result;
    case types.GET_INSPECTION_LAST_TRANSACTION_FAILURE:
      var result = Object.assign({}, state, {
        lastTransaction: null
      });
      return result;
    case types.GET_IF_TWO_LEVEL_REQUEST:
      return {
        ...state,
        isTwoLevel: false
      };
    case types.GET_IF_TWO_LEVEL_SUCCESS:
      var result = Object.assign({}, state, {
        isTwoLevel: action.data
      });
      return result;
    case types.GET_IF_TWO_LEVEL_FAILURE:
      var result = Object.assign({}, state, {
        isTwoLevel: false
      });
      return result;
    case types.UPDATE_FORM:
      var result = Object.assign({}, state, {
        form: action.data
      });
      return result;
    case types.GET_PRODUCT_INFO_BY_PSN_REQUEST:
      return {
        ...state,
        productInfoByPsn: null
      };
    case types.GET_PRODUCT_INFO_BY_PSN_SUCCESS:
      var result = Object.assign({}, state, {
        productInfoByPsn: action.data
      });
      return result;
    case types.GET_PRODUCT_INFO_BY_PSN_FAILURE:
      var result = Object.assign({}, state, {
        productInfoByPsn: null
      });
      return result;
    case types.PRODUCT_FILE_INFO_BY_ID_REQUEST:
      return {
        ...state,
        productFileInfo: null
      };
    case types.PRODUCT_FILE_INFO_BY_ID_SUCCESS:
      var result = Object.assign({}, state, {
        productFileInfo: action.data,
        tempProdFiles: action.data
      });
      return result;
    case types.PRODUCT_FILE_INFO_BY_ID_FAILURE:
      var result = Object.assign({}, state, {
        productFileInfo: null
      });
      return result;
    case types.CREATE_INSPECTION_REPORT_REQUEST:
      return {
        ...state,
        isLoadingCreateUsageReport: true,
        isSuccessCreateUsageReport: false
      };
    case types.CREATE_INSPECTION_REPORT_SUCCESS:
      var result = Object.assign({}, state, {
        isLoadingCreateUsageReport: false,
        isSuccessCreateUsageReport: action.data.isSuccessful
      });
      return result;
    case types.CREATE_INSPECTION_REPORT_FAILURE:
      var result = Object.assign({}, state, {
        isLoadingCreateUsageReport: false,
        isSuccessCreateUsageReport: false
      });
      return result;
    case types.GET_INSPECTION_LAST_TRANSACTION_REQUEST:
      return {
        ...state,
        lastTransaction: null
      };
    case types.GET_INSPECTION_LAST_TRANSACTION_SUCCESS:
      var result = Object.assign({}, state, {
        lastTransaction: action.data
      });
      // result.form.startDate = action.data;
      return result;
    case types.GET_INSPECTION_LAST_TRANSACTION_FAILURE:
      var result = Object.assign({}, state, {
        lastTransaction: null
      });
      return result;
    case types.GET_IF_TWO_LEVEL_REQUEST:
      return {
        ...state,
        isTwoLevel: false
      };
    case types.GET_IF_TWO_LEVEL_SUCCESS:
      var result = Object.assign({}, state, {
        isTwoLevel: action.data
      });
      // result.form.startDate = action.data;
      return result;
    case types.GET_IF_TWO_LEVEL_FAILURE:
      var result = Object.assign({}, state, {
        isTwoLevel: false
      });
      return result;
    // case types.UPDATE_FORM:
    //   var result = Object.assign({}, state, {
    //     form: action.data,
    //   });
    //   return result;
    case types.GET_DOWNLOAD_REPORT_REQUEST:
      return {
        ...state,
        isLoadingDownloadReport: true
      };
    case types.GET_DOWNLOAD_REPORT_SUCCESS:
      var result = Object.assign({}, state, {
        downloadReports: action.data,
        hasDownloadReports: true,
        isLoadingDownloadReport: false
      });
      return result;
    case types.GET_DOWNLOAD_REPORT_FAILURE:
      var result = Object.assign({}, state, {
        downloadReports: null,
        hasDownloadReports: true,
        isLoadingDownloadReport: false
      });
      return result;
    case types.USAGE_OPEN_MODAL:
      return { open: true, dimmer: action.dimmer };
    case types.USAGE_CLOSE_MODAL:
      return { open: false };
    case types.CREATE_INSPECTION_REPORT_RESET:
      var result = Object.assign({}, state, {
        isSuccessCreateUsageReport: false
      });
      return result;
    case types.SET_PRODUCT_FILE:
      var result = Object.assign({}, state, {
        selectedProductFile: action.data
      });
      return result;
    case types.SELECT_PRODUCT_FILE:
      var result = Object.assign({}, state, {});

      result.productFileInfo.forEach((fileItem) => {
        fileItem.productFileInfos.forEach((fileInfoItem) => {
          if (fileInfoItem.fileId == action.data.data.fileId) {
            fileInfoItem.isSelected = action.data.isSelected;

            if (action.data.isSelected) {
              result.selectedFiles.push({
                fileId: fileInfoItem.fileId,
                productFile: `${fileInfoItem.prodId}|${fileInfoItem.fileId}`
              });
            } else {
              result.selectedFiles = result.selectedFiles.filter(
                (s) => s.fileId != action.data.data.fileId
              );
            }
          }
        });
      });
      return result;
    case types.SELECT_ALL_PRODUCT_FILE:
      var result = Object.assign({}, state, {});
      var newFileInfo = [];

      result.isSelectedAll = action.data.isSelectedAll;

      result.productFileInfo.forEach((fileItem) => {
        fileItem.productFileInfos.forEach((fileInfoItem) => {
          fileInfoItem.isSelected = action.data.isSelectedAll;

          if (action.data.isSelectedAll) {
            result.selectedFiles.push({
              fileId: fileInfoItem.fileId,
              productFile: `${fileInfoItem.prodId}|${fileInfoItem.fileId}`
            });
          } else {
            result.selectedFiles = [];
          }
        });

        newFileInfo.push(fileItem);
      });

      result.productFileInfo = newFileInfo;
      return result;
    case types.FILTER_PRODUCT_FILE:
      var result = Object.assign({}, state, {
        filterProduct: action.data
      });
      // result.productFileInfos.filter((s) => s.fileVolumes.includes(filterText));
      // result.productFileInfo.forEach((fileItem) => {
      //   fileItem.productFileInfos.forEach((fileInfoItem) => {
      //     if (fileInfoItem.fileId == action.data.data.fileId) {
      //       fileInfoItem.isSelected = action.data.isSelected;

      //       if (action.data.isSelected) {
      //         result.selectedFiles.push({
      //           fileId: fileInfoItem.fileId,
      //           productFile: `${fileInfoItem.prodId}|${fileInfoItem.fileId}`,
      //         });
      //       } else {
      //         result.selectedFiles = result.selectedFiles.filter(
      //           (s) => s.fileId != action.data.data.fileId
      //         );
      //       }
      //     }
      //   });
      // });
      return result;
    default:
      return state;
  }
};

export default reportedUsageReducer;
