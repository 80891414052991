import HomePage from "../components/sidebar/welcome-label";
import History from "../views/history/history";
import LandingPage from "../views/landing-page/landing-page";
import locationSelection from "../views/location-selection/location-selection";
import ReportedUsage from "../views/reported-usage/reported-usage";
import RoleMaintenance from "../views/roles-maintenance/roles-maintenance";
import SubmittedUsage from "../views/submitted-usage/submitted-usage";
import UnitMaintenance from "../views/unit-maintenance/unit-maintenance";
import UserMaintenance from "../views/user-maintenance/user-maintenance";

const routes = [
  // {
  //   name: "home",
  //   to: "/home/location-selection",
  //   value: "Home",
  //   icon: "home",
  //   component: LandingPage,
  //   sidebar: true,
  // },
  // {
  // name: "home-start",
  // to: "/home/start",
  // value: "Home",
  // icon: "home",
  // component: HomePage,
  // sidebar: false,
  // },
  {
    name: "production-volume",
    to: "/home/production-volume",
    value: "Production Volumes",
    icon: "lab",
    component: UserMaintenance,
    sidebar: false,
  },
  {
    name: "role-maintenance",
    to: "/home/role-maintenance",
    value: "User Maintenance",
    icon: "users",
    component: RoleMaintenance,
    sidebar: false,
  },
  {
    name: "unit-maintenance",
    to: "/home/unit-maintenance",
    value: "UOM Maintenance",
    icon: "setting",
    component: UnitMaintenance,
    sidebar: false,
  },
  {
    name: "history",
    to: "/home/history",
    value: "History",
    icon: "clock outline",
    component: History,
    sidebar: false,
  },
  {
    name: "location-selection",
    to: "/home/location-selection",
    value: "Home",
    icon: "home",
    component: locationSelection,
    sidebar: true,
  },
  {
    name: "reported-usage",
    to: "/home/reported-usage",
    value: "Reported Usage",
    icon: "",
    component: ReportedUsage,
    sidebar: false,
  },
  {
    name: "submitted-usage",
    to: "/home/submitted-usage",
    value: "Submitted Usage",
    icon: "",
    component: SubmittedUsage,
    sidebar: false,
  },
];

export default routes;
