import React, { Component, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Card, Container, Form, Header, List } from "semantic-ui-react";
import { loginCard } from "../../assets/js/common";
import { Common } from "../../helpers/common.helper";
import IdentityProvider from "../../enums/identity-provider";
const API_ROOT = `${process.env.REACT_APP_API_ROOT}`;
const IDENTITY_PROVIDER = `${process.env.REACT_APP_IDENTITY_PROVIDER}`;
class Main extends Component {
  state = { username: null, password: null, isSuccessful: false };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleLogin = () => {
    const { username, password } = this.state;
    if (username && password) this.setState({ isSuccessful: true });
  };

  componentDidMount() {}

  // render() {
  //   var userId = localStorage.getItem("userId");

  //   if (window) {
  //     localStorage.setItem("framesLength", window.parent.frames.length);
  //   }

  //   if (!userId) {
  //     Common.toggleLogin();
  //   } else {
  //     return <Redirect to="/home/location-selection" />;
  //   }
  //   return "";
  // }
  render(){
    var userId = localStorage.getItem("userId");

    if (window) {
      localStorage.setItem("framesLength", window.parent.frames.length);
    }

    if (IDENTITY_PROVIDER == IdentityProvider.Iam) {
      var framesLength = localStorage.getItem("framesLength");
      if (framesLength > 0) {
        setTimeout(() => {
          var externalAuthURL = `${API_ROOT}auth/external`;
          window.location = externalAuthURL;

          console.log("IAM challenge Login");
        }, 100);
      } else {
        if (!userId) {
          var externalAuthURL = `${API_ROOT}auth/external`;
          window.location = externalAuthURL;

          console.log("IAM challenge Login");
        } else {
          console.log("IAM already logged Login");
          return <Redirect to="/home" />;
        }
      }
    } else if (IDENTITY_PROVIDER == IdentityProvider.B2c) {
      var framesLength = localStorage.getItem("framesLength");
      if (framesLength > 0) {
        Common.toggleSilentLogin();
        console.log("B2C challenge Login");
      } else {
        if (!userId) {
          Common.toggleLogin();
          console.log("B2C login");
        } else {
          console.log("B2C already logged in");
          return <Redirect to="/home" />;
        }
      }
    }
    return "";

  }
}

export default Main;
