import { requests } from "../request-factory";
import { mapDataToQueryString } from "../request-helpers";

export default {
  getProducts: (applicantPsn, manufacturerPsn) =>
    requests.get(
      `productitem/get-product-items?${mapDataToQueryString({
        applicantPsn,
        manufacturerPsn,
      })}`
    ),
  getProductInfo: () => requests.get(`productinfo`),
  getProductInfosByPsn: (psn) =>
    requests.get(`productinfo/psn?${mapDataToQueryString({ psn })}`),
  getProductFileInfosByProdId: (prodId, customerPsn) =>
    requests.get(
      `productfileinfo/prod-id?${mapDataToQueryString({ prodId, customerPsn })}`
    ),
  getInspectionReport: (
    partySiteNumber,
    productType,
    fileNumber,
    volume,
    currentPage,
    pageSize
  ) =>
    requests.get(
      `inspectionreport?${mapDataToQueryString({
        partySiteNumber,
        productType,
        fileNumber,
        volume,
        currentPage,
        pageSize,
      })}`
    ),
  getLastTransaction: (partySiteNumber, productType, fileNumber, volume, ccn) =>
    requests.get(
      `inspectionreport/last-transaction?${mapDataToQueryString({
        partySiteNumber,
        productType,
        fileNumber,
        volume,
        ccn,
      })}`
    ),
  getIfTwoLevel: (productType, ccn) =>
    requests.get(
      `unitofmeasurement/check-two-level?${mapDataToQueryString({
        productType,
        ccn,
      })}`
    ),

  getDownloadReport: (data) =>
    requests.post(`inspectionreport/download-transaction`, data),

  createUsageReport: (data) => requests.post(`inspectionreport`, data),
};
