import { combineReducers } from "redux";
import userMaintenance from "../views/user-maintenance/user-maintenance.reducer";
import rolesMaintenance from "../views/roles-maintenance/roles-maintenance.reducer";
import locationSelection from "../views/location-selection/location-selection.reducer";
import usageReport from "../views/reported-usage/reported-usage.reducer";
import callback from "../views/callback/callback.reducer";
import unitOfMeasurement from "../views/unit-maintenance/unit-maintenance.reducer";

const rootReducer = combineReducers({
  userMaintenance,
  rolesMaintenance,
  locationSelection,
  usageReport,
  callback,
  unitOfMeasurement
});

export default rootReducer;
