import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "semantic-ui-react";
import {
  Card,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Label,
  Segment
} from "semantic-ui-react";
import PsnLocation from "../psn-location/psn-location";
import * as actions from "./location-selection.action";

class LocationSelection extends Component {
  state = {};

  handleCurrentManufacturer = (selectedId) => {
    var { locationSelection } = this.props;

    this.props.setCurrentManufacturer(selectedId);
    localStorage.setItem("selectedManufacturerId", selectedId);
    this.props.history.push("/home/reported-usage");
  };
  componentDidMount() {
    this.props.getManufacturers();
  }
  render() {
    var { locationSelection } = this.props;
    var { data } = locationSelection;

    const breadSections = [
      { key: "Home", content: "Home", link: true },
      {
        key: "location-selection",
        content: "Manufacturing Location Selection",
        active: true
      }
    ];

    const BreadcrumbExampleShorthand = () => (
      <Breadcrumb divider="/" size="large" sections={breadSections} />
    );

    return (
      <div>
        <div style={{ height: "200px;" }}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Segment>
                  <Grid.Row style={{ height: "50px" }}>
                    <Grid.Column>{BreadcrumbExampleShorthand()}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <h1>Production Volume Reporting</h1>
                      <h4>
                        If there is missing or incorrect information, please
                        contact your local Field Engineer.
                      </h4>
                    </Grid.Column>
                  </Grid.Row>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div style={{ width: "80%", padding: "20px", margin: "auto" }}>
          <Container fluid>
            <h2>Manufacturing Locations</h2>

            <Divider hidden fitted />
            <p>
              Please select an option from the following list of eligible
              manufacturing locations by Party Site Number (PSN) for production
              volume reporting. If the location is not listed, please contact
              your local Field Engineer
            </p>
            <Divider hidden fitted />

            {data &&
              data.length > 0 &&
              data.map((data, index) => (
                <Grid columns={2}>
                  <Grid.Column width={14}>
                    <Grid.Row stretched>
                      <Segment
                        key={index}
                        as={Link}
                        className="location-selection"
                        style={{ display: "block" }}
                        onClick={this.handleCurrentManufacturer.bind(
                          this,
                          data.manufacturerId
                        )}
                      >
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <th
                                align="left"
                                className="description"
                              >{`${data.customerName} | PARTY SITE NUMBER (PSN): ${data.psn}`}</th>
                              <td rowSpan="3" className="arrow">
                                <Icon name="arrow right" />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Divider />
                              </td>
                            </tr>
                            <tr>
                              <td className="meta">{`${data.addressLine1} ${data.addressLine2}${data.addressLine3}${data.addressLine4} | ${data.city}, ${data.state} ${data.postalCode} | ${data.country}`}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Segment>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              ))}
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locationSelection: state.locationSelection
});

const mapDispatchToProps = (dispatch) => ({
  getManufacturers: () => dispatch(actions.getManufacturers()),
  setCurrentManufacturer: (data) =>
    dispatch(actions.setCurrentManufacturer(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelection);
