import * as types from "./unit-maintenance.type";
import api from "../../services/api";

const dispatchResult = (obj, type) => {
  return {
    type: type,
    data: obj
  };
};

export const getAllUom = () => {
  return async (dispatch) => {
    dispatch(dispatchResult(null, types.UNIT_REQUEST));
    return await api.Role.getAllUom()
      .then((result) => {
        dispatch(dispatchResult(result, types.UNIT_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          dispatchResult(
            result.response && result.response.data,
            types.UNIT_FAILURE
          )
        );
      });
  };
};

export const getUnitOfMeasurement = (productType) => {
  return async (dispatch) => {
    dispatch(dispatchResult(null, types.UNIT_OF_MEASUREMENT_REQUEST));
    return await api.Unit.getUnitOfMeasurement(productType)
      .then((result) => {
        dispatch(dispatchResult(result, types.UNIT_OF_MEASUREMENT_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          dispatchResult(
            result.response && result.response.data,
            types.UNIT_OF_MEASUREMENT_FAILURE
          )
        );
      });
  };
};
