export const isRequired = (fieldName) => `${fieldName} is required`;

export const mustMatch = (otherFieldName) => {
  return (fieldName) => `${fieldName} must match ${otherFieldName}`;
};

export const minLength = (length) => {
  return (fieldName) => `${fieldName} must be at least ${length} characters`;
};

export const maxLength = (length) => {
  return (fieldName) => `${fieldName} must be ${length} characters only`;
};

export const isEmail = () => `Invalid email address`;

export const isPhoneNumber = () => `Invalid phone number`;

export const isNumber = (fieldName) => `${fieldName} must be number only`;

export const isPassword = () =>
  `Password should contains characters from at least three of the following categories: uppercase letters, lowercase letters, numbers and special characters`;

export const isPasswordHasAmpersand = () =>
  `Special character ampersand "&" is not allowed`;

export const isPasswordHasDoubleChars = () =>
  `Consecutive double characters is not allowed`;

export const isPasswordUseName = () =>
  `Your name is not allowed to be used as your password`;

export const isLetters = () => `must be letter only`;
