import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { Icon, Menu } from "semantic-ui-react";
import { Common } from "../../helpers/common.helper";
const API_ROOT = `${process.env.REACT_APP_API_ROOT}`;

class Logout extends Component {
  state = {
    navigate: false
  };

  // componentDidMount() {
  //   this.handleLogout();
  // }

  handleLogout = () => {
    var b2cToken = Common.getB2cToken();
    localStorage.clear();

    Common.toggleLogout(b2cToken);
  };

  render() {
    const { navigate } = this.state;
    const { isLogoutSuccess } = this.props;
    const framesLength = parseInt(localStorage.getItem("framesLength"));

    if (framesLength == 0) {
      return (
        <Menu.Item
          name="logout"
          className="inactive-item"
          onClick={this.handleLogout}
        >
          <Icon name="log out" className="item-icon" />
          Log out
        </Menu.Item>
        // <h5>Logout</h5>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
