import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class Auth extends Component {
  state = {};

  componentDidMount() {
    var token = this.props.match.params.token;
    if (token && token !== null) {
      localStorage.setItem("userId", 1);
      localStorage.setItem("token", JSON.stringify(token));
    }
  }

  render() {
    const userId = localStorage.getItem("userId");

    if (userId != "") {
      return <Redirect to="/home" />;
    }
    return "";
  }
}

export default Auth;
