import * as types from "./location-selection.type";
import api from "../../services/api";

const dispatchResult = (obj, type) => {
  return {
    type: type,
    data: obj,
  };
};

export const getManufacturers = () => {
  return async (dispatch) => {
    dispatch(dispatchResult(null, types.GET_MANUFACTURERS_REQUEST));
    return await api.Manufacturer.getManufacturers()
      .then((result) => {
        dispatch(dispatchResult(result, types.GET_MANUFACTURERS_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          dispatchResult(
            result.response && result.response.data,
            types.GET_MANUFACTURERS_FAILURE
          )
        );
      });
  };
};

export const getManufacturersById = (manufacturerId) => {
  return async (dispatch) => {
    dispatch(dispatchResult(null, types.GET_MANUFACTURERS_BY_ID_REQUEST));
    return await api.Manufacturer.getManufacturerById(manufacturerId)
      .then((result) => {
        dispatch(dispatchResult(result, types.GET_MANUFACTURERS_BY_ID_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          dispatchResult(
            result.response && result.response.data,
            types.GET_MANUFACTURERS_BY_ID_FAILURE
          )
        );
      });
  };
};

export const setCurrentManufacturer = (data) => {
  return async (dispatch) => {
    dispatch(dispatchResult(data, types.SET_CURRENT_MANUFACTURER));
  };
};
