import React, { Component } from "react";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes, getDay } from "date-fns";
import { Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import * as usageActions from "./reported-usage.action";
import * as customerActions from "../location-selection/location-selection.action";
import * as uomActions from "../unit-maintenance/unit-maintenance.action";
import {
  Accordion,
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  List,
  Menu,
  Message,
  Pagination,
  Table,
  Modal,
  Tab,
  Segment,
  Dropdown,
  Checkbox,
  Label
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Common, convertUnixTimeToDate } from "../../helpers/common.helper";
import DatePicker from "react-datepicker";
import { required } from "../../validator/error-message";
import {
  createValidation,
  getError,
  validate,
  validateField
} from "../../validator/validator";
import { toast } from "react-semantic-toasts";
import CsvDownload from "react-json-to-csv";
import styles from "./style.module.css";
import reportedUsageReducer from "./reported-usage.reducer";

const rules = [
  validate("startDate", "Usage Start Date", required),
  validate("endDate", "Usage End Date", required),
  validate("usageQuantity", "Quantity", required),
  validate("unitOfMeasurement", "Unit Of Measure", required),
  validate("confirmUsageQuantity", "Quantity", required),
  validate("confirmUnitOfMeasurement", "Unit Of Measure", required)
];

class ReportedUsage extends Component {
  state = {
    activeMenuIndex: 0,
    activeIndex: 0,
    activeItem: "account",
    isSuccessGetCustomer: false,
    filterData: null,
    showUsageEntryModal: false,
    showDownloadHistoryModal: false,
    selectProd: "",
    selectFileNo: "",
    selectVol: "",
    selectCcn: "",
    isDisablePane: true,
    newActivePane: "tab1",
    startDate: "",
    endDate: "",
    autoStartDate: "",
    autoEndDate: "",
    downloadStartDate: "",
    downloadEndDate: "",
    Quatity: 0,
    ConfirmQty: 0,
    UnitOfMeasurement: "",
    ConfirmUom: "",
    showErrors: false,
    lastTransaction: null,
    isLastTransaction: false,
    isTwoLevel: false,
    hasDownloadReport: false,
    newDownloadData: null,
    showAlternateUOM: false,
    newUomValues: [],
    altUOMValues: [],
    showErrorBar: false,
    showSuccessBar: false,
    defaultUom: "",
    prodIdList: ""
  };

  handleRenderScheduleDate = (date) => {
    if (date) {
      var date = new Date(date);
      var day = ("0" + date.getDate()).slice(-2);
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var year = date.getFullYear();

      var time = year + "-" + month + "-" + day + "T00:00:00.000Z";

      return time;
    }

    return null;
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeMenuIndex } = this.state;
    const newIndex = activeMenuIndex === index ? -1 : index;

    this.setState({ activeMenuIndex: newIndex });
  };

  dateIsValid(date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  handleDateChange = (name, value) => {
    let { form } = this.props;
    let isValid = this.dateIsValid(value);
    console.log(`Is ${name} Valid: `, isValid);
    console.log("Date value: ", value);
    var tempVal = new Date(value).toLocaleDateString("en-US");
    console.log("Date tempVal value: ", tempVal);
    var newVal = this.handleDateTest(tempVal);
    console.log("Date newVal value: ", newVal);

    const newState = validateField(rules, form, name, newVal);

    this.props.updateForm(newState, form);
  };

  handleChange = (event) => {
    let { form } = this.props;

    const newState = validateField(
      rules,
      form,
      event.target.name,
      event.target.value
    );

    this.props.updateForm(newState, form);
  };
  handleCheckIfTwoLevel = (productType, ccn) => {
    let { form } = this.props;

    this.props.getIfTwoLevel(productType, ccn).then((response) => {
      const newState = validateField(rules, form, "isTwoLevel", response);
      this.props.updateForm(newState, form);
    });
  };

  handleDropdownChange = (e, properties) => {
    let { form, unitOfMeasurement } = this.props;

    const newState = validateField(
      rules,
      form,
      properties.name,
      properties.value
    );

    // const { unitOfMeasurementOptions } = unitOfMeasurement;

    // let uom = unitOfMeasurementOptions.filter(
    //   (x) => x.key == properties.value
    // )[0];

    // if (
    //   uom.text.toLowerCase() == "feet" ||
    //   uom.text.toLowerCase() == "square feet"
    // ) {
    //   if (uom.text.toLowerCase() == "feet") {
    //     let dropDownValues = [
    //       { key: 1, value: "Feet", text: "Feet" },
    //       { key: 2, value: "Meters", text: "Meters" },
    //     ];
    //     this.setState({ showAlternateUOM: true, altUOMValues: dropDownValues });
    //   }

    //   if (uom.text.toLowerCase() == "square feet") {
    //     let dropDownValues = [
    //       { key: 1, value: 1, text: "Square Feet" },
    //       { key: 2, value: "Square Meters", text: "Square Meters" },
    //     ];
    //     this.setState({ showAlternateUOM: true, altUOMValues: dropDownValues });
    //   }
    // } else {
    //   this.setState({ showAlternateUOM: false, altUOMValues: [] });

    //   const newStateUomAlt = validateField(
    //     rules,
    //     form,
    //     "unitOfMeasurementAlt",
    //     ""
    //   );

    //   this.props.updateForm(newStateUomAlt, form);

    //   const newStateUomAltConfirm = validateField(
    //     rules,
    //     form,
    //     "confirmUnitOfMeasurementAlt",
    //     ""
    //   );

    //   this.props.updateForm(newStateUomAltConfirm, form);
    // }

    this.props.updateForm(newState, form);

    // if (properties.name == "unitOfMeasurement") {
    //   const newState2 = validateField(
    //     rules,
    //     form,
    //     "unitOfMeasurement",
    //     properties.text
    //   );
    //   this.props.updateForm(newState2, form);
    // }
    // const newStateUom = validateField(rules, form, "uomText", uom.text);
    // this.props.updateForm(newStateUom, form);
  };

  handleDropdownChangeAlt = (e, properties) => {
    let { form, unitOfMeasurement } = this.props;

    const newState = validateField(
      rules,
      form,
      properties.name,
      properties.value
    );

    this.props.updateForm(newState, form);
  };

  handleNumeric = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ value: e.target.value });
      this.handleChange(e);
    }
  };

  getErrorFor = (field) => {
    if (!this.state.showErrors) {
      return null;
    }

    const { form } = this.props;
    let errors = getError(field, form, "");
    return errors;
  };

  validateEndDate = (field) => {
    let startDate = new Date(this.props.form.startDate);
    let endDate = new Date(this.props.form.endDate);
    let dateNow = new Date();

    if (this.state.showErrors) {
      if (this.props.form.endDate) {
        if (startDate.getTime() >= endDate.getTime()) {
          return "End Date should be later than Start Date.";
        }

        if (endDate.getTime() > dateNow.getTime()) {
          return "End Date should  NOT be later than today.";
        }
      }

      return this.getErrorFor(field);
    }
    return null;
  };

  validateEndDateDownload = (field) => {
    let startDate = new Date(this.props.form.downloadStartDate);
    let endDate = new Date(this.props.form.downloadEndDate);
    if (this.state.showErrors) {
      if (isNaN(endDate.getTime())) {
        return "Please enter End Date.";
      } else {
        if (startDate.getTime() >= endDate.getTime()) {
          return "End Date should be later than Start Date.";
        }
      }

      return this.getErrorFor(field);
    }
    return null;
  };

  validateUOMAlt = (field) => {
    const { form, unitOfMeasurement } = this.props;

    const { unitOfMeasurementOptions } = unitOfMeasurement;

    let uom = unitOfMeasurementOptions.filter(
      (x) => x.key == form.unitOfMeasurement
    )[0];

    if (
      uom.text.toLowerCase() == "feet" ||
      uom.text.toLowerCase() == "square feet"
    ) {
      if (!form.unitOfMeasurementAlt) {
        return "Alternate Unit of Measure is required";
      }
    }

    return this.getErrorFor(field);
  };

  validateQuantity = (field) => {
    if (this.state.showErrors) {
      if (!this.props.form.isTwoLevel && this.props.form.usageQuantity <= 0) {
        return "Quantity should be greater than 0";
      }
    }
    return this.getErrorFor(field);
  };

  validateConfirmation = (field) => {
    const { form } = this.props;
    if (field == "confirmUnitOfMeasurementAlt") {
      if (
        this.props.form.unitOfMeasurementAlt !=
        this.props.form.confirmUnitOfMeasurementAlt
      ) {
        return "Confirmation for Alternate UOM should match";
      }
    }

    if (field == "confirmUnitOfMeasurement") {
      if (
        this.props.form.unitOfMeasurement !=
        this.props.form.confirmUnitOfMeasurement
      ) {
        return "Confirmation for UOM should match";
      }
    }

    if (field == "confirmUsageQuantity") {
      if (
        this.props.form.usageQuantity != this.props.form.confirmUsageQuantity
      ) {
        return "Confirmation for QTY should match";
      }
    }

    return this.getErrorFor(field);
  };

  validatedDownloadStartDate = (field) => {
    var initialDate = new Date();
    initialDate.setHours(0, 0, 0, 0);
    initialDate = initialDate.setFullYear(initialDate.getFullYear() - 2);

    //initialDate = new Date(initialDate.setFullYear(initialDate.getFullYear()-2));

    let startDate = new Date(this.props.form.downloadStartDate);

    startDate.setHours(0, 0, 0, 0);
    if (new Date(initialDate) > startDate) {
      return "You can only download data the data for the past 24 months.";
    }

    return this.getErrorFor(field);
  };

  handleFilterProduct = (e) => {
    const filterText = e.target.value.toLowerCase();
    const { usageReport } = this.props;
    const { productFileInfo } = usageReport;
    const newFilter = productFileInfo.map((element) => {
      return {
        ...element,
        productFileInfos: element.productFileInfos.filter(
          (subElement) =>
            subElement.fileVolume.toLowerCase().indexOf(filterText) !== -1
        ),
        productCounter: element.productFileInfos.filter(
          (subElement) =>
            subElement.fileVolume.toLowerCase().indexOf(filterText) !== -1
        ).length
      };
    });
    // newFilter.productFileInfos.forEach((item) => {
    //   item.delete();
    // });
    for (var i = newFilter.length - 1; i > -1; i--) {
      if (newFilter[i].productCounter === 0) {
        newFilter.splice(i, 1);
      }
    }

    this.props.filterProductFile(newFilter);
  };

  handleGetUsage = () => {
    console.log("handleGetUsage");
  };

  hasItemsSelected = () => {
    const { locationSelection, form, usageReport } = this.props;
    const { selectedFiles } = usageReport;

    if (selectedFiles.length > 0) {
      return false;
    }
    return true;
  };

  handleDownloadTransaction = (startDate, endDate) => {
    const { locationSelection, form, usageReport } = this.props;
    const customer = locationSelection.customerDetails || {};
    const { selectedFiles } = usageReport;

    var initialDate = new Date();
    initialDate = initialDate.setFullYear(initialDate.getFullYear() - 2);

    if (new Date(initialDate).setDate(initialDate - 1) > startDate) {
      this.setState({ showErrors: true });
      return;
    }
    if (isNaN(endDate)) {
      this.setState({ showErrors: true });
      return;
    }
    let products = selectedFiles
      .map((s) => {
        return s.productFile;
      })
      .join(";");

    this.props
      .getDownloadReport({
        customer: customer,
        startDate: this.handleRenderScheduleDate(startDate),
        endDate: this.handleRenderScheduleDate(endDate),
        partySiteNumber: customer.psn,
        products: products
      })
      .then((response) => {
        if (response) {
          const byteCharacters = atob(response);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          var blob = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.style = "display: none";
          a.href = url;
          a.download = `Inspection_Report.xlsx`;
          a.click();
          window.URL.revokeObjectURL(url);

          this.setState({ hasDownloadReport: true });
          this.handleHistoryModal(false);
        }
      });
  };

  handleUsageContinueButton = (activeIndex) => {
    if (activeIndex.activeIndex == 0) {
      return "CONTINUE";
    } else {
      return "SUBMIT";
    }
  };

  handleUsageCancelButton = (aIndex) => {
    if (aIndex.activeIndex == 0) {
      return "CANCEL";
    } else {
      // this.setState({ activeIndex: 1 });
      return "BACK";
    }
  };

  handleUsageCancelClick = (aIndex) => {
    if (aIndex == 0) {
      this.handleInitializeValue();
      this.handleUsageModal(false);
      this.setState({ showErrors: false, showAlternateUOM: false });
    } else {
      this.setState({ activeIndex: 0, showErrors: false });
    }
  };

  handleUsageCreateNewClick = () => {
    const { unitOfMeasurementOptions } = this.props.unitOfMeasurement;

    this.setState({ showErrors: false, showErrorBar: false });
    this.handleUsageModal(true);
    if (unitOfMeasurementOptions.length > 0) {
      const unitOption = unitOfMeasurementOptions[0].text;
      this.handlePopulateUom(unitOption);
    }
  };
  handleGetInspectionReport = (item, productType, psn) => {
    const { currentPage, pageSize } = this.props.usageReport;
    this.setState({ filterData: item });

    this.setState({
      selectProd: productType,
      selectFileNo: item.fileNumber,
      selectVol: item.volume,
      selectCcn: item.ccn
    });

    this.props.getInspectionReport(
      psn,
      productType,
      item.fileNumber,
      item.volume,
      currentPage,
      pageSize
    );
    // this.handleCheckIfTwoLevel(productType, item.ccn);
    this.props.getUnitOfMeasurement(productType);

    this.handleGetLastTransaction(
      productType,
      item.fileNumber,
      item.volume,
      item.ccn
    );
    this.props.setProductFile(item);
  };

  handlePopulateUom = (unit) => {
    const { unitOfMeasurementOptions } = this.props.unitOfMeasurement;

    if (unit.toLowerCase() == "feet" || unit.toLowerCase() == "square feet") {
      if (unit.toLowerCase() == "feet") {
        let dropDownValues = [
          { key: 1, value: "Feet", text: "Feet" },
          { key: 2, value: "Meters", text: "Meters" }
        ];
        this.setState({ newUomValues: dropDownValues });
      }
      if (unit.toLowerCase() == "square feet") {
        let dropDownValues = [
          { key: 1, value: "Square Feet", text: "Square Feet" },
          { key: 2, value: "Square Meters", text: "Square Meters" }
        ];
        this.setState({ newUomValues: dropDownValues });
      }
    } else {
      this.setState({ newUomValues: unitOfMeasurementOptions });
    }
  };

  handleDateTest = (date) => {
    var splitDate = date.split("/");
    const newDate = new Date(date);

    return newDate;
  };
  handleGetLastTransaction = (selectProd, selectFileNo, selectVol, ccn) => {
    const { locationSelection, form } = this.props;
    const customer = locationSelection.customerDetails || {};

    this.props
      .getInspectionLastTransaction(
        customer.psn,
        selectProd,
        selectFileNo,
        selectVol,
        ccn
      )
      .then((response) => {
        const newState = validateField(
          rules,
          form,
          "startDate",
          this.handleDateTest(response.nextTransaction)
        );

        this.props.updateForm(newState, form);

        if (response.isTwoLevel) {
          const newState2 = validateField(
            rules,
            form,
            "endDate",
            this.handleDateTest(response.autoEndDate)
          );

          this.props.updateForm(newState2, form);
        } else {
          const newState2 = validateField(rules, form, "endDate", null);

          this.props.updateForm(newState2, form);
        }

        const newState3 = validateField(
          rules,
          form,
          "isTwoLevel",
          response.isTwoLevel
        );

        this.props.updateForm(newState3, form);
      });
  };

  handleInitializeValue = () => {
    const { form } = this.props;

    const newState1 = validateField(rules, form, "usageQuantity", 0);

    this.props.updateForm(newState1, form);

    const newState2 = validateField(rules, form, "confirmUsageQuantity", 0);

    this.props.updateForm(newState2, form);

    const newState3 = validateField(rules, form, "unitOfMeasurement", null);

    this.props.updateForm(newState3, form);

    const newState4 = validateField(
      rules,
      form,
      "confirmUnitOfMeasurement",
      null
    );

    this.props.updateForm(newState4, form);

    this.setState({ activeIndex: 0, showErrors: false });

    this.setState({ isDisablePane: true });
  };

  handleCloseViewDownloadDialog = () => {
    this.setState({ hasDownloadReport: false });
  };

  handleUsageModal = (value) => {
    this.setState({ showUsageEntryModal: value });
  };
  handleHistoryModal = (value) => {
    var initialDate = new Date();
    initialDate = initialDate.setFullYear(initialDate.getFullYear() - 2);

    this.handleDateChange("downloadStartDate", initialDate);
    this.setState({ showDownloadHistoryModal: value });
    this.props.selectAllFiles(false);
  };
  handlePageChange = (currentPage, pageSize) => {
    const { selectProd, selectFileNo, selectVol } = this.state;
    const { locationSelection } = this.props;
    const customer = locationSelection.customerDetails || {};

    let psn = customer.psn;
    let fileNumber = selectFileNo;
    let volume = selectVol;
    let productType = selectProd;

    this.props.getInspectionReport(
      psn,
      productType,
      fileNumber,
      volume,
      currentPage,
      pageSize
    );
  };

  handleIfReadOnlyDate = () => {
    const { usageReport, form } = this.props;
    const { inspectionReports } = usageReport;

    let retVal = false;
    if (inspectionReports.data.length > 0 || form.isTwoLevel) {
      retVal = true;
    }
    return retVal;
  };

  handleCheckInputUsage = (value) => {
    const { form, usageReport, locationSelection, unitOfMeasurement } =
      this.props;
    const { productInfoByPsn, selectedProductFile } = usageReport;
    const customer = locationSelection.customerDetails || {};
    const productInfo = productInfoByPsn.filter(
      (p) => p.prodId == selectedProductFile.prodId
    )[0];
    const { activeIndex, selectFileNo, selectVol, selectCcn } = this.state;
    const { unitOfMeasurementData } = unitOfMeasurement;
    let unitOfMeasurementDetails = null;
    let dateNow = new Date();

    unitOfMeasurementDetails = unitOfMeasurementData.filter(
      (u) => u.prodTypeItemNo == form.unitOfMeasurement
    )[0];

    if (activeIndex == 0) {
      form.validationErrors = createValidation(form, rules);

      let isValid = true;

      let startDate = new Date(this.props.form.startDate);
      let endDate = new Date(this.props.form.endDate);

      if (startDate.getTime() >= endDate.getTime()) {
        isValid = false;
      }

      if (endDate.getTime() > dateNow.getTime()) {
        this.setState({ showErrors: true });
        isValid = false;
      }

      if (!form.isTwoLevel && form.usageQuantity <= 0) {
        this.setState({ showErrors: true });
        isValid = false;
      }

      if (
        this.props.form.usageQuantity != this.props.form.confirmUsageQuantity ||
        this.props.form.unitOfMeasurement !=
          this.props.form.confirmUnitOfMeasurement
      ) {
        isValid = false;
      }

      if (Object.IsEmpty(form.validationErrors) && isValid) {
        this.setState({ isDisablePane: value });
        this.setState({ activeIndex: 1, showErrorBar: false });
      } else {
        this.setState({ showErrors: true, showErrorBar: true });
      }
    } else if (activeIndex == 1) {
      var model = {
        inspectionData: {
          partySiteNumber: customer.psn,
          fileNumber: selectFileNo,
          volume: selectVol,
          ccn: selectCcn,
          productType: productInfo.productType,
          productTypeItemNumber: productInfo.productItemNumber,
          serviceLineInstanceNumber: productInfo.serviceLineInstanceNumber,
          systemNumber: productInfo.systemNumber,
          quantity: parseInt(form.usageQuantity),
          // unitOfMeasurementId: form.unitOfMeasurement,
          twoLevel: this.props.form.isTwoLevel,
          usageStartDate: this.handleRenderScheduleDate(form.startDate),
          usageEndDate: this.handleRenderScheduleDate(form.endDate),
          prodId: selectedProductFile.prodId,
          fileId: selectedProductFile.fileId,
          unitOfMeasurement: this.props.form.unitOfMeasurement,
          unitOfMeasurementAlt: form.unitOfMeasurementAlt
        }
      };
      this.props.createUsageReport(model).then(() => {});
      this.handleUsageModal(false);
      this.handleInitializeValue();
    }
  };

  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeIndex });
  };

  handleSelectFile = (data, isSelected) => {
    this.props.selectFile(data, isSelected);
  };
  handleSelectAllFiles = (isSelectedAll) => {
    this.props.selectAllFiles(isSelectedAll);
  };
  componentDidMount() {
    this.getInitItems();
  }

  getInitItems() {
    const { locationSelection } = this.props;
    const customer = locationSelection.customerDetails || {};
    var customerId =
      locationSelection.currentManufacturer || Common.getManufacturerId();

    if (customerId) {
      this.props.getManufacturersById(customerId).then((result) => {
        this.props.getProductInfosByPsn(result.psn).then((infoResult) => {
          var prodIds = infoResult.map((item) => {
            return item.prodId;
          });

          this.setState({ prodIdList: prodIds });

          this.props
            .getProductFileInfosByProdId(prodIds, result.psn)
            .then((fileResult) => {
              if (fileResult) {
                this.handleGetInspectionReport(
                  fileResult[0].productFileInfos[0],
                  infoResult[0].productType,
                  result.psn
                );
              }
            });
        });
        this.setState({ isSuccessGetCustomer: true });
      });
    }
  }

  render() {
    const { activeMenuIndex, activeItem, activeIndex, newDownloadData } =
      this.state;
    const { locationSelection, usageReport, unitOfMeasurement } = this.props;
    const { unitOfMeasurementOptions, unitOfMeasurementData } =
      unitOfMeasurement;

    const {
      productFiles,
      inspectionReports,
      currentPage,
      pageSize,
      isLoadingReport,
      productFileInfo,
      form,
      isSuccessCreateUsageReport,
      isLoadingCreateUsageReport,
      isLoadingDownloadReport,
      lastTransaction,
      downloadReports,
      hasDownloadReports,
      selectedProductFile,
      isSelectedAll,
      filterProduct
    } = usageReport;

    const customer = locationSelection.customerDetails || {};
    let startDate = null;
    let endDate = null;
    let unitOfMeasurementDetails = null;

    if (form) {
      startDate = new Date(form.startDate).toLocaleDateString();
      endDate = new Date(form.endDate).toLocaleDateString();

      if (unitOfMeasurementData) {
        unitOfMeasurementDetails = unitOfMeasurementData.filter(
          (u) => u.prodTypeItemNo == form.unitOfMeasurement
        )[0];
      }
    }

    if (isSuccessCreateUsageReport) {
      this.props.resetCreateUsageReport();
      // this.getInitItems();
      this.setState({ showSuccessBar: true, showUsageEntryModal: false });
      var item = {
        fileNumber: this.state.selectFileNo,
        volume: this.state.selectVol,
        ccn: this.state.selectCcn,
        fileId: selectedProductFile.fileId,
        prodId: selectedProductFile.prodId
      };

      this.props
        // .getProductFileInfosByProdId(selectedProductFile.prodId, customer.psn)
        .getProductFileInfosByProdId(this.state.prodIdList, customer.psn)
        .then((fileResult) => {
          if (fileResult) {
            this.handleGetInspectionReport(
              // fileResult[0].productFileInfos[0],
              item,
              this.state.selectProd,
              customer.psn
            );
          }
        });

      // this.handleGetInspectionReport(item, this.state.selectProd, customer.psn);
    }

    const panes = [];
    panes.push(
      {
        name: "Report Details",
        menuItem: (
          <Menu.Item style={{ borderRight: "1px solid #BBC1C8" }}>
            <b>Report Details</b>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane key={1} style={{ padding: "30px" }}>
            {/* <Table compact sortable striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>PRODUCT TYPE</Table.HeaderCell>
                  <Table.HeaderCell>FILE NUMBER</Table.HeaderCell>
                  <Table.HeaderCell>VOLUME</Table.HeaderCell>
                  <Table.HeaderCell>CCN</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{this.state.selectProd}</Table.Cell>
                  <Table.Cell>{this.state.selectFileNo}</Table.Cell>
                  <Table.Cell>{this.state.selectVol}</Table.Cell>
                  <Table.Cell>{this.state.selectCcn}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table> */}

            <Grid>
              <Grid.Row>
                <Grid.Column width="5">
                  <Label className={styles.labelLight}>
                    MANUFACTURING LOCATION
                    <Label className={styles.label}>
                      {customer.customerName}
                    </Label>
                  </Label>
                </Grid.Column>

                <Grid.Column width="5">
                  <Label className={styles.labelLight}>
                    PARTY SITE NUMBER (PSN)
                    <Label className={styles.label}>{customer.psn}</Label>
                  </Label>
                </Grid.Column>

                <Grid.Column width="5">
                  <Label className={styles.labelLight}>
                    ADDRESS
                    <Label className={styles.label}>
                      {customer.addressLine1 +
                        " " +
                        customer.addressLine2 +
                        " " +
                        customer.addressLine3 +
                        " " +
                        customer.addressLine4 +
                        " " +
                        customer.city +
                        ", " +
                        customer.state +
                        " " +
                        customer.postalCode +
                        " " +
                        customer.country}
                    </Label>
                  </Label>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width="5">
                  <Label className={styles.labelLight}>
                    PRODUCT TYPE
                    <Label className={styles.label}>
                      {this.state.selectProd}
                    </Label>
                  </Label>
                </Grid.Column>

                <Grid.Column width="5">
                  <Label className={styles.labelLight}>
                    FILE NO.
                    <Label className={styles.label}>
                      {this.state.selectFileNo}
                    </Label>
                  </Label>
                </Grid.Column>

                <Grid.Column width="5">
                  <Label className={styles.labelLight}>
                    VOLUME
                    <Label className={styles.label}>
                      {this.state.selectVol}
                    </Label>
                  </Label>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Form>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    {form.isTwoLevel && (
                      <strong>
                        2 Level Production Volume Start Date and End Date
                      </strong>
                    )}
                    {!form.isTwoLevel && (
                      <strong>
                        Select the Production Volume Reporting start date and
                        end date.
                      </strong>
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width="5">
                    <Form.Field
                      control={DatePicker}
                      label="Start Date *"
                      placeholder="Start Date"
                      name="startDate"
                      onChange={(date) => {
                        this.handleDateChange("startDate", date);
                      }}
                      selected={form.startDate}
                      value={form.startDate || ""}
                      error={this.getErrorFor("startDate")}
                      autoComplete="off"
                      showDisabledMonthNavigation
                      readOnly={this.handleIfReadOnlyDate()}
                    />
                  </Grid.Column>
                  <Grid.Column width="5">
                    <Form.Field
                      control={DatePicker}
                      label="End Date *"
                      placeholder="End Date"
                      name="endDate"
                      onChange={(date) => {
                        this.handleDateChange("endDate", date);
                      }}
                      selected={form.endDate}
                      value={form.endDate || ""}
                      error={this.validateEndDate("endDate")}
                      autoComplete="off"
                      readOnly={form.isTwoLevel}
                      showDisabledMonthNavigation
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <strong>
                      Enter the production volume quantity and unit of measure
                      since the last report.
                    </strong>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width="5">
                    <Form.Field
                      control={Input}
                      label="Quantity *"
                      placeholder="Quantity"
                      name="usageQuantity"
                      onChange={this.handleNumeric}
                      value={form.usageQuantity || ""}
                      error={this.validateQuantity("usageQuantity")}
                      autoComplete="off"
                      min="0"
                      // type="number"
                    />
                  </Grid.Column>
                  <Grid.Column width="6">
                    <Form.Field
                      control={Dropdown}
                      compact
                      selection
                      selectOnBlur={false}
                      label="Unit Of Measure *"
                      placeholder="Select Unit Of Measure"
                      name="unitOfMeasurement"
                      options={this.state.newUomValues}
                      onChange={(e, properties) => {
                        this.handleDropdownChange(e, properties);
                      }}
                      value={form.unitOfMeasurement || ""}
                      error={this.getErrorFor("unitOfMeasurement")}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <strong>Confirm the quantity and unit of measure.</strong>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width="5">
                    <Form.Field>
                      <Form.Field
                        control={Input}
                        label="Quantity *"
                        placeholder="Quantity"
                        name="confirmUsageQuantity"
                        onChange={this.handleNumeric}
                        value={form.confirmUsageQuantity || ""}
                        error={this.validateConfirmation(
                          "confirmUsageQuantity"
                        )}
                        autoComplete="off"
                        min="0"
                        // type="number"
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width="6">
                    <Form.Field
                      control={Dropdown}
                      compact
                      selection
                      selectOnBlur={false}
                      label="Unit Of Measure *"
                      placeholder="Select Unit Of Measure"
                      name="confirmUnitOfMeasurement"
                      options={this.state.newUomValues}
                      onChange={(e, properties) => {
                        this.handleDropdownChange(e, properties);
                      }}
                      value={form.confirmUnitOfMeasurement || ""}
                      error={this.validateConfirmation(
                        "confirmUnitOfMeasurement"
                      )}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Tab.Pane>
        )
      },
      {
        name: "Review Report",
        menuItem: (
          <Menu.Item
            disabled={this.state.isDisablePane}
            style={{ borderRight: "1px solid #BBC1C8" }}
          >
            <b>Review Report</b>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane key={2}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <strong>Review Report</strong>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  Please review the report details and click Submit below if
                  correct. To edit the report, click Back. An invoice will be
                  generated for this report.
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="5">
                  <Label className={styles.labelLight}>
                    MANUFACTURING LOCATION
                    <Label className={styles.label}>
                      {customer.customerName}
                    </Label>
                  </Label>
                </Grid.Column>

                <Grid.Column width="5">
                  <Label className={styles.labelLight}>
                    PARTY SITE NUMBER (PSN)
                    <Label className={styles.label}>{customer.psn}</Label>
                  </Label>
                </Grid.Column>

                <Grid.Column width="5">
                  <Label className={styles.labelLight}>
                    ADDRESS
                    <Label className={styles.label}>
                      {customer.addressLine1 +
                        " " +
                        customer.addressLine2 +
                        " " +
                        customer.addressLine3 +
                        " " +
                        customer.addressLine4 +
                        " " +
                        customer.city +
                        ", " +
                        customer.state +
                        " " +
                        customer.postalCode +
                        " " +
                        customer.country}
                    </Label>
                  </Label>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width="5">
                  <Label className={styles.labelLight}>
                    PRODUCT TYPE
                    <Label className={styles.label}>
                      {this.state.selectProd}
                    </Label>
                  </Label>
                </Grid.Column>

                <Grid.Column width="5">
                  <Label className={styles.labelLight}>
                    FILE NO.
                    <Label className={styles.label}>
                      {this.state.selectFileNo}
                    </Label>
                  </Label>
                </Grid.Column>

                <Grid.Column width="5">
                  <Label className={styles.labelLight}>
                    VOLUME
                    <Label className={styles.label}>
                      {this.state.selectVol}
                    </Label>
                  </Label>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="5">
                  <Label className={styles.labelLight}>
                    START DATE
                    <Label className={styles.label}>
                      {new Date(this.props.form.startDate).toLocaleDateString()}
                    </Label>
                  </Label>
                </Grid.Column>

                <Grid.Column width="5">
                  <Label className={styles.labelLight}>
                    END DATE
                    <Label className={styles.label}>
                      {new Date(this.props.form.endDate).toLocaleDateString()}
                    </Label>
                  </Label>
                </Grid.Column>

                <Grid.Column width="5">
                  <Label className={styles.labelLight}>
                    PRODUCTION VOLUME REPORTED THIS PERIOD
                    <Label className={styles.label}>
                      {this.props.form.usageQuantity}{" "}
                      {this.props.form.unitOfMeasurement}
                    </Label>
                  </Label>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        )
      }
    );

    return (
      <Fragment>
        <div className={styles.mainContainer}>
          <Modal open={this.state.showDownloadHistoryModal} size="small">
            <Modal.Header
              className="ui"
              style={{ backgroundColor: "#303741", color: "white" }}
            >
              Download History
            </Modal.Header>
            <Modal.Content>
              {/* <strong>
                Enter the date range for Production Volume reporting Download
                history and select the file/volumes for export. (You can only
                download the data for the past 24 months)
              </strong> */}
              <br />

              <Form>
                <Grid>
                  <Grid.Row>
                    <Grid.Column textAlign="center">
                      <strong>
                        Select the date range, Product Type(s), and File
                        Volume(s) to download.
                      </strong>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="5">
                      <Form.Field
                        control={DatePicker}
                        label="Start Date *"
                        placeholder="Start Date"
                        name="downloadStartDate"
                        onChange={(date) => {
                          this.handleDateChange("downloadStartDate", date);
                        }}
                        selected={form.downloadStartDate}
                        value={form.downloadStartDate || ""}
                        error={this.validatedDownloadStartDate(
                          "downloadStartDate"
                        )}
                        autoComplete="off"
                        showDisabledMonthNavigation
                      />
                    </Grid.Column>
                    <Grid.Column width="5">
                      <Form.Field
                        control={DatePicker}
                        label="End Date *"
                        placeholder="End Date"
                        name="downloadEndDate"
                        onChange={(date) => {
                          this.handleDateChange("downloadEndDate", date);
                        }}
                        selected={form.downloadEndDate}
                        value={form.downloadEndDate || ""}
                        error={this.validateEndDateDownload("downloadEndDate")}
                        autoComplete="off"
                        showDisabledMonthNavigation
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <strong>Product Types and File Volumes</strong>
                      <Divider fitted hidden />
                      <div
                        style={{
                          height: "200px",
                          overflowY: "scroll",
                          backgroundColor: "#EDF1F6",
                          border: "1px solid #CCD1D8",
                          padding: "20px"
                        }}
                      >
                        <Checkbox
                          checked={isSelectedAll}
                          style={{
                            marginLeft: "15px"
                          }}
                          onChange={(e, { checked }) => {
                            this.props.selectAllFiles(checked);
                          }}
                        />

                        <strong
                          style={{
                            marginLeft: "10px"
                          }}
                        >
                          Select All
                        </strong>

                        <Divider fitted hidden />

                        {productFileInfo &&
                          productFileInfo.map((productFile, index) => {
                            return (
                              <Fragment>
                                {productFile.productFileInfos &&
                                  productFile.productFileInfos.map(
                                    (file, idx) => {
                                      return (
                                        <Segment key={idx}>
                                          <Table
                                            basic="very"
                                            fixed
                                            compact="very"
                                          >
                                            <Table.Body>
                                              <Table.Row>
                                                <Table.Cell width="1">
                                                  <Checkbox
                                                    checked={file.isSelected}
                                                    onChange={(
                                                      e,
                                                      { checked }
                                                    ) => {
                                                      this.handleSelectFile(
                                                        file,
                                                        checked
                                                      );
                                                    }}
                                                  />
                                                </Table.Cell>
                                                <Table.Cell>{`${productFile.productType} | ${file.fileNumber}-${file.volume}`}</Table.Cell>
                                              </Table.Row>
                                            </Table.Body>
                                          </Table>
                                        </Segment>
                                      );
                                    }
                                  )}
                              </Fragment>
                            );
                          })}
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <div className={styles.actionLabel}>
                <b>*</b>Required
              </div>
              <Button onClick={this.handleHistoryModal.bind(this, false)}>
                Cancel
              </Button>
              <Button
                primary
                onClick={this.handleDownloadTransaction.bind(
                  this,
                  form.downloadStartDate,
                  form.downloadEndDate
                )}
                disabled={this.hasItemsSelected()}
                loading={isLoadingDownloadReport}
              >
                Download data
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal open={this.state.showUsageEntryModal} size="small">
            <Modal.Header
              className="ui"
              style={{ backgroundColor: "#303741", color: "white" }}
            >
              New Production Volume Report
            </Modal.Header>

            {this.state.showErrorBar && (
              <div className={styles.errorBar}>
                <Icon name="exclamation"></Icon>
                <label>Please correct the error(s) below to continue.</label>
              </div>
            )}
            <Modal.Content scrolling className={styles.modalContent}>
              <Tab
                panes={panes}
                activeIndex={activeIndex}
                onTabChange={this.handleTabChange}
                className={styles.modalContentTab}
              ></Tab>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={this.handleUsageCancelClick.bind(this, activeIndex)}
                loading={isLoadingCreateUsageReport}
              >
                {this.handleUsageCancelButton({ activeIndex })}
              </Button>

              <Button
                onClick={this.handleCheckInputUsage.bind(this, false)}
                primary
                loading={isLoadingCreateUsageReport}
              >
                {this.handleUsageContinueButton({ activeIndex })}
              </Button>
            </Modal.Actions>
          </Modal>
          <Container fluid className={styles.container}>
            {this.state.showSuccessBar && (
              <div className={styles.successBar}>
                <Icon name="check"></Icon>
                <label>Your report has been submitted.</label>
              </div>
            )}
            <Grid divided="vertically">
              <Grid.Row className={styles.whiteBoxContainer}>
                <Grid.Column>
                  <Segment>
                    <Grid divided>
                      <Grid.Row>
                        <Grid.Column>
                          <Button
                            primary
                            basic
                            as={Link}
                            to="/home/location-selection"
                          >
                            {`< Back to Manufacturing Locations`}
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <h1>Production Volume Reporting</h1>
                          <h4>
                            If there is missing or incorrect information, please
                            contact your local Field Engineer.
                          </h4>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width="5">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <h4 style={{ color: "#93A6BA" }}>
                                    MANUFACTURING LOCATION
                                  </h4>
                                </td>
                              </tr>
                              <tr>
                                <td>{customer.customerName}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Grid.Column>
                        <Grid.Column width="4">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <h4 style={{ color: "#93A6BA" }}>
                                    PARTY SITE NUMBER (PSN)
                                  </h4>
                                </td>
                              </tr>
                              <tr>
                                <td>{customer.psn}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Grid.Column>
                        <Grid.Column width="5">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <h4 style={{ color: "#93A6BA" }}>ADDRESS</h4>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {customer.addressLine1}{" "}
                                  {customer.addressLine2}{" "}
                                  {customer.addressLine3}{" "}
                                  {customer.addressLine4}{" "}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {customer.city}, {customer.state}{" "}
                                  {customer.postalCode}
                                </td>
                              </tr>
                              <tr>
                                <td>{customer.country}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Grid divided>
                    <Grid.Row>
                      <Grid.Column width="4">
                        <Segment>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <Input
                                    onChange={this.handleFilterProduct.bind(
                                      this
                                    )}
                                    icon="search"
                                    name="search"
                                    placeholder="Search by Product Type or File Number"
                                    style={{ width: "250px" }}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Accordion>
                                    {filterProduct &&
                                      filterProduct.map((pF, index) => {
                                        return (
                                          <Fragment>
                                            <Accordion.Title
                                              key={index}
                                              active={activeMenuIndex === index}
                                              index={index}
                                              onClick={this.handleClick}
                                              disabled={pF.productCounter === 0}
                                            >
                                              <Icon name="dropdown" />
                                              <b>{pF.productType}</b>
                                            </Accordion.Title>
                                            <Accordion.Content
                                              active={activeMenuIndex === index}
                                            >
                                              <Menu secondary vertical compact>
                                                {pF.productFileInfos &&
                                                  pF.productFileInfos.map(
                                                    (file, idx) => {
                                                      return (
                                                        <Menu.Item
                                                          key={idx}
                                                          name={`File: ${file.fileNumber} Volume: ${file.volume}`}
                                                          className={
                                                            selectedProductFile &&
                                                            selectedProductFile.fileId ==
                                                              file.fileId
                                                              ? "active-file-info"
                                                              : ""
                                                          }
                                                          onClick={this.handleGetInspectionReport.bind(
                                                            this,
                                                            file,
                                                            pF.productType,
                                                            customer.psn
                                                          )}
                                                          style={{
                                                            width: "250px"
                                                          }}
                                                        />
                                                      );
                                                    }
                                                  )}
                                              </Menu>
                                            </Accordion.Content>
                                          </Fragment>
                                        );
                                      })}
                                  </Accordion>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Segment>
                      </Grid.Column>
                      <Grid.Column width="10">
                        <div style={{ marginLeft: "20px" }}>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column width="9" textAlign="left">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <h3>Report History</h3>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <h4>
                                          Production Volume Reports within the
                                          last 24 months.
                                        </h4>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                              <Grid.Column width="9" textAlign="left">
                                <Button
                                  secondary
                                  onClick={this.handleHistoryModal.bind(
                                    this,
                                    true
                                  )}
                                >
                                  <Icon name="download" />
                                  Download History
                                </Button>
                              </Grid.Column>
                              <Grid.Column
                                width="5"
                                verticalAlign="bottom"
                                textAlign="right"
                              >
                                <Button
                                  primary
                                  onClick={this.handleUsageCreateNewClick.bind(
                                    this
                                  )}
                                >
                                  Report New Production Volume Quantity
                                </Button>
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                              <Form
                                loading={isLoadingReport}
                                style={{ width: "90%" }}
                              >
                                <Table sortable striped>
                                  <Table.Header>
                                    <Table.Row>
                                      <Table.HeaderCell
                                        className={styles.tableHeader}
                                      >
                                        Product Type
                                      </Table.HeaderCell>
                                      <Table.HeaderCell
                                        className={styles.tableHeader}
                                      >
                                        File:Volume
                                      </Table.HeaderCell>
                                      <Table.HeaderCell
                                        className={styles.tableHeader}
                                      >
                                        Report Start Date
                                      </Table.HeaderCell>
                                      <Table.HeaderCell
                                        className={styles.tableHeader}
                                      >
                                        Report End Date
                                      </Table.HeaderCell>
                                      <Table.HeaderCell
                                        className={styles.tableHeader}
                                      >
                                        Reported Quantity
                                      </Table.HeaderCell>
                                      <Table.HeaderCell
                                        className={styles.tableHeader}
                                      >
                                        Unit
                                      </Table.HeaderCell>
                                      <Table.HeaderCell
                                        className={styles.tableHeader}
                                      >
                                        Report Submitted Date
                                      </Table.HeaderCell>
                                      <Table.HeaderCell
                                        className={styles.tableHeader}
                                      >
                                        Reported By
                                      </Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>
                                  <Table.Body className={styles.table}>
                                    {inspectionReports &&
                                      inspectionReports.data &&
                                      inspectionReports.data.length > 0 &&
                                      inspectionReports.data.map(
                                        (data, index) => (
                                          <Table.Row key={index}>
                                            <Table.Cell>
                                              {data.productType}
                                            </Table.Cell>
                                            <Table.Cell>
                                              {data.fileVolume}
                                            </Table.Cell>
                                            <Table.Cell>
                                              {data.reportStartDate}
                                            </Table.Cell>
                                            <Table.Cell>
                                              {data.reportEndDate}
                                            </Table.Cell>
                                            <Table.Cell>
                                              {data.quantity}
                                            </Table.Cell>
                                            <Table.Cell>{data.unit}</Table.Cell>
                                            <Table.Cell>
                                              {data.reportCreatedDate}
                                            </Table.Cell>
                                            <Table.Cell>
                                              {data.reportedBy}
                                            </Table.Cell>
                                          </Table.Row>
                                        )
                                      )}
                                  </Table.Body>
                                  <Table.Footer>
                                    <Table.Row>
                                      <Table.HeaderCell colSpan="8">
                                        {inspectionReports &&
                                        inspectionReports.totalPages > 0 ? (
                                          <Pagination
                                            activePage={
                                              inspectionReports.currentPage
                                            }
                                            totalPages={
                                              inspectionReports.totalPages
                                            }
                                            onPageChange={(
                                              e,
                                              { activePage }
                                            ) => {
                                              this.handlePageChange(
                                                activePage,
                                                pageSize
                                              );
                                            }}
                                          />
                                        ) : (
                                          <Message>No Order Found</Message>
                                        )}
                                      </Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Footer>
                                </Table>
                              </Form>
                            </Grid.Row>
                          </Grid>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  usageReport: state.usageReport,
  locationSelection: state.locationSelection,
  form: state.usageReport.form,
  unitOfMeasurement: state.unitOfMeasurement
});

const mapDispatchToProps = (dispatch) => ({
  getProducts: (applicantPsn, manufacturerPsn) =>
    dispatch(usageActions.getProducts(applicantPsn, manufacturerPsn)),
  getProductInfo: () => dispatch(usageActions.getProductInfo()),
  getProductInfosByPsn: (partySiteNumber) =>
    dispatch(usageActions.getProductInfosByPsn(partySiteNumber)),
  getInspectionReport: (
    partySiteNumber,
    productType,
    fileNumber,
    volume,
    currentPage,
    pageSize
  ) =>
    dispatch(
      usageActions.getInspectionReport(
        partySiteNumber,
        productType,
        fileNumber,
        volume,
        currentPage,
        pageSize
      )
    ),
  getDownloadReport: (data) => dispatch(usageActions.getDownloadReport(data)),
  getManufacturersById: (customerId) =>
    dispatch(customerActions.getManufacturersById(customerId)),
  getProductFileInfosByProdId: (prodIds, customerPsn) =>
    dispatch(usageActions.getProductFileInfosByProdId(prodIds, customerPsn)),
  updateForm: (newForm, form) =>
    dispatch(usageActions.updateForm(newForm, form)),
  getUnitOfMeasurement: (productType) =>
    dispatch(uomActions.getUnitOfMeasurement(productType)),

  getInspectionLastTransaction: (
    partySiteNumber,
    productType,
    fileNumber,
    volume,
    ccn
  ) =>
    dispatch(
      usageActions.getInspectionLastTransaction(
        partySiteNumber,
        productType,
        fileNumber,
        volume,
        ccn
      )
    ),
  getIfTwoLevel: (productType, ccn) =>
    dispatch(usageActions.getIfTwoLevel(productType, ccn)),
  createUsageReport: (data) => dispatch(usageActions.createUsageReport(data)),
  resetCreateUsageReport: (data) =>
    dispatch(usageActions.resetCreateUsageReport()),
  setProductFile: (data) => dispatch(usageActions.setProductFile(data)),
  selectFile: (data, isSelected) =>
    dispatch(usageActions.selectFile(data, isSelected)),
  selectAllFiles: (isSelectedAll) =>
    dispatch(usageActions.selectAllFiles(isSelectedAll)),
  filterProductFile: (data) => dispatch(usageActions.filterProductFile(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportedUsage);
