import * as types from "./user-maintenance.type";

let initialState = {
  data: [],
  loading: false,
  isSuccess: false
};

const userMaintenanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USERS_REQUEST:
      return {
        ...state,
        loading: true,
        isSuccess: false
      };
    case types.USERS_SUCCESS:
      var result = Object.assign({}, state, {
        data: action.data,
        loading: false,
        isSuccess: true
      });
      return result;
    case types.USERS_FAILURE:
      var result = Object.assign({}, state, {
        data: [],
        loading: false,
        isSuccess: false
      });
      return result;
    default:
      return state;
  }
};

export default userMaintenanceReducer;
