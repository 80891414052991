import * as types from "./location-selection.type";

let initialState = {
  data: [],
  loading: false,
  isSuccess: false,
  customerDetails: null,
  isloadingCustomer: false,
  isSuccessCustomer: false,
  currentManufacturer: null,
};

const locationSelectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MANUFACTURERS_REQUEST:
      return {
        ...state,
        loading: true,
        isSuccess: false,
      };
    case types.GET_MANUFACTURERS_SUCCESS:
      var result = Object.assign({}, state, {
        data: action.data,
        loading: false,
        isSuccess: true,
      });
      return result;
    case types.GET_MANUFACTURERS_FAILURE:
      var result = Object.assign({}, state, {
        data: [],
        loading: false,
        isSuccess: false,
      });
      return result;
    case types.GET_MANUFACTURERS_BY_ID_REQUEST:
      return {
        ...state,
        isloadingCustomer: true,
        isSuccessCustomer: false,
      };
    case types.GET_MANUFACTURERS_BY_ID_SUCCESS:
      var result = Object.assign({}, state, {
        customerDetails: action.data,
        isloadingCustomer: false,
        isSuccessCustomer: true,
      });
      return result;
    case types.GET_MANUFACTURERS_BY_ID_FAILURE:
      var result = Object.assign({}, state, {
        customerDetails: null,
        isloadingCustomer: false,
        isSuccessCustomer: false,
      });
      return result;
    case types.SET_CURRENT_MANUFACTURER:
      var result = Object.assign({}, state, {
        currentManufacturer: action.data,
      });
      return result;
    default:
      return state;
  }
};

export default locationSelectionReducer;
