import React, { Component } from "react";
import { Fragment } from "react";
import { Container, Divider } from "semantic-ui-react";
import HomeButtonStart from "./home-start-button";

class HomePage extends Component {
  state = {};
  render() {
    return (
      <Fragment>
        <div style={{ width: "50%", padding: "20px", margin: "auto" }}>
          <Container fluid>
            <Divider hidden />
            <h2>Welcome</h2>
            <p>
              To begin reporting your UL Mark production, please click below.
            </p>
            <HomeButtonStart />
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default HomePage;
