import { divide } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Button, Container, Divider, Grid } from "semantic-ui-react";
import PsnInfo from "../psn-info/psn-info";

class SubmittedUsage extends React.Component {
  render() {
    const { locationSelection, usageReport } = this.props;
    const { selectedProductFile } = usageReport;
    var productInfo = null;

    if (!locationSelection.customerDetails) {
      return <Redirect to="/home/location-selection" />;
    }

    return (
      <div style={{ width: "80%", padding: "20px" }}>
        <Container fluid>
          <PsnInfo custDetails={locationSelection} />
          <div style={{ width: "30%", padding: "20px", margin: "auto" }}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <h2>Report submitted</h2>
                  <Divider fitted hidden />
                  <p>
                    You have completed this production usage report. Do you have
                    additional product types to report production volume usage
                    for this manufacturing location?
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Button
                    as={Link}
                    to={`/home/reported-usage`}
                    primary
                    style={{ width: "345px" }}
                  >
                    Yes, take me to the product list
                  </Button>
                  <Divider fitted hidden />
                  <Divider fitted hidden />
                  <Divider fitted hidden />
                  <Button
                    as={Link}
                    to="/home/location-selection"
                    secondary
                    style={{ width: "345px" }}
                  >
                    No, I'm finished
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locationSelection: state.locationSelection,
  usageReport: state.usageReport,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SubmittedUsage);
