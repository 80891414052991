import React from "react";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

const HomeButtonStart = () => (
  <div>
    <Link to="/home/location-selection">
      <Button primary>Click to Begin</Button>
    </Link>
  </div>
);

export default HomeButtonStart;
