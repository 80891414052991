import * as types from "./roles-maintenance.type";
import api from "../../services/api";

const dispatchResult = (obj, type) => {
  return {
    type: type,
    data: obj
  };
};

export const getAllRoles = () => {
  return async (dispatch) => {
    dispatch(dispatchResult(null, types.ROLES_REQUEST));
    return await api.Role.getAllRoles()
      .then((result) => {
        dispatch(dispatchResult(result, types.ROLES_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          dispatchResult(
            result.response && result.response.data,
            types.ROLES_FAILURE
          )
        );
      });
  };
};
