import * as message from "./message";
import rules from "./validation-rule";

export const required = (text) => {
  text = text ? text.toString().trim() : text;
  return text && text.length > 0 ? null : message.isRequired;
};

export const requiredCheckbox = (value) => {
  return typeof value !== "undefined" ? null : message.isRequired;
};

export const requiredCheckboxWithNull = (value) => {
  return typeof value !== "undefined" && value !== null
    ? null
    : message.isRequired;
};

export const mustMatch = (field, fieldName) => {
  return (text, state) => {
    return state[field] === text ? null : message.mustMatch(fieldName);
  };
};

export const minLength = (length) => {
  return (text) => {
    return text && text.trim().length >= length
      ? null
      : message.minLength(length);
  };
};

export const maxLength = (length) => {
  return (text) => {
    return text && text.trim().length <= length
      ? null
      : message.maxLength(length);
  };
};

export const email = (text) => {
  if (text === undefined || text === "") return null;

  text = text ? text.trim() : text;
  return rules.emailRule().test(text) ? null : message.isEmail;
};

export const numberOnly = (text) => {
  //text = text ? text.trim() : text;
  return rules.numberRule().test(text) ? null : message.isNumber;
};

// export const password = (text) => {
//   text = text ? text.trim() : text;

//   if (Common.getUserId() > 0) {
//     // for change password only, already logged in
//     var name = Common.getFullName().toLowerCase();
//     var nameArr = name.split(" ");
//     if (
//       text ? nameArr.indexOf(text) > -1 || name.replace(" ", "") == text : text
//     ) {
//       return message.isPasswordUseName;
//     }
//   } else {
//     // register: get data from form
//     // reset: ???
//   }

//   // if (text ? text.includes("&") : text) { // includes doesn’t have IE support
//   if (text ? text.indexOf("&") !== -1 : text) {
//     return message.isPasswordHasAmpersand;
//   }

//   if (!rules.passwordRule().test(text)) {
//     return message.isPassword;
//   } else if (rules.passwordRuleDoubleChars().test(text)) {
//     return message.isPasswordHasDoubleChars;
//   } else {
//     return null;
//   }
// };

export const lettersOnly = (text) => {
  //text = text ? text.trim() : text;
  return rules.lettersRule().test(text) ? null : message.isLetters;
};

export const maxLengthNumberInput = (e, length) => {
  return parseInt(e.target.value) > length ? true : false;
};

export const phoneNumber = (text) => {
  //text = text ? text.trim() : text;
  if (text === undefined || text === "") return null;
  return rules.phoneNumber().test(text) ? null : message.isPhoneNumber;
};
