import { requests } from "../request-factory";
import { mapDataToQueryString } from "../request-helpers";

export default {
  getManufacturers: () => requests.get(`manufacturerinfo`),
  getManufacturerById: (manufacturerId) =>
    requests.get(
      `manufacturerinfo/single?${mapDataToQueryString({
        manufacturerId
      })}`
    )
};
