import IdentityProvider from "../enums/identity-provider";
import { Common } from "../helpers/common.helper";

const API_BASE = `${process.env.REACT_APP_API_BASE}`;
const IDENTITY_PROVIDER = `${process.env.REACT_APP_IDENTITY_PROVIDER}`;

const checkTokenExpirationMiddleware = (store) => (next) => (action) => {
  const expiration = Common.getTokenExpiration();

  if (!expiration) {
    if (IDENTITY_PROVIDER == IdentityProvider.B2c) {
      if (window.location.href.indexOf("callback?code") == -1) {
        localStorage.setItem("framesLength", window.parent.frames.length);

        Common.toggleLogin();
      }
    } else if (IDENTITY_PROVIDER == IdentityProvider.Iam) {
      localStorage.setItem("framesLength", window.parent.frames.length);

      var externalLogoutURL = `${API_BASE}auth/logout`;
      window.top.location.href = externalLogoutURL;
    }
  }

  next(action);
};
export default checkTokenExpirationMiddleware;
