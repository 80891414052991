import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Placeholder,
  Popup,
  Segment
} from "semantic-ui-react";
import * as usageActions from "../reported-usage/reported-usage.action";

class LandingPage extends Component {
  state = {};

  redirectToLink(url) {
    window.open(url, "_blank");
  }

  componentDidMount() {
    this.props.getProductInfo();
  }

  render() {
    const { usageReport } = this.props;
    let { isLoadingProductInfo, productInfo } = usageReport;
    let isEligible = false;

    if (productInfo) isEligible = productInfo.length > 0;

    return (
      <div class="myDiv">
        <div class="bg"></div>
        <div class="layer"></div>
        <h1 className="welcome-header" style={{ color: "#ffffff" }}>
          Welcome To Label Services
        </h1>
        <div className="label-applications">
          <h3 className="label-applications-header">
            The new, customer-centric Label Services applications allow you to:
          </h3>
          <Divider hidden fitted />
          <div>
            <Grid padded divided>
              <Grid.Row>
                <Grid.Column width="3">
                  <h3>Order Labels</h3>
                  <p>Order Labels, track orders, and view order history.</p>
                  <Button
                    primary
                    onClick={this.redirectToLink.bind(
                      this,
                      "https://fieldservices.ul.com/"
                    )}
                  >
                    Order labels
                  </Button>
                </Grid.Column>
                <Grid.Column width="4">
                  <h3>Production Volume</h3>
                  <p>Report UL mark production volume.</p>
                  {isLoadingProductInfo ? (
                    <Icon name="spinner" />
                  ) : isEligible ? (
                    <Button primary as={Link} to="/home/location-selection">
                      Report production volume
                    </Button>
                  ) : null}
                </Grid.Column>
                <Grid.Column width="3">
                  <h3>Request Assistance</h3>
                  <p>Request assistance from your designated Label Center.</p>
                  <Button
                    primary
                    onClick={this.redirectToLink.bind(
                      this,
                      "https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers/"
                    )}
                  >
                    Request Assistance
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usageReport: state.usageReport
});

const mapDispatchToProps = (dispatch) => ({
  getProductInfo: () => dispatch(usageActions.getProductInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
