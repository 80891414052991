import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Header, Table } from "semantic-ui-react";
import * as userActions from "./user-maintenance.action";

class UserMaintenance extends Component {
  state = {};
  componentDidMount() {
    this.props.getAllUsers();
  }
  render() {
    const { userMaintenance } = this.props;
    const { data } = userMaintenance;
    console.log(userMaintenance);
    return (
      <Fragment>
        <Header>List Of Users</Header>
        <Table compact sortable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>First Name</Table.HeaderCell>
              <Table.HeaderCell>Last Name</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data &&
              data.length > 0 &&
              data.map((data, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{data.id}</Table.Cell>
                  <Table.Cell>{data.email}</Table.Cell>
                  <Table.Cell>{data.firstName}</Table.Cell>
                  <Table.Cell>{data.lastName}</Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userMaintenance: state.userMaintenance
});

const mapDispatchToProps = (dispatch) => ({
  getAllUsers: () => dispatch(userActions.getAllUsers())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMaintenance);
