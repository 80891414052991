import User from "./user/user-service";
import Role from "./role/role-service";
import Customer from "./customer/customer-service";
import Usage from "./usage/usage-service";
import Auth from "./auth/auth-service";
import Unit from "./unit/uom-service";
import Manufacturer from "./manufacturer/manufacturer-service";

export default {
  User,
  Role,
  Customer,
  Usage,
  Auth,
  Unit,
  Manufacturer
};
