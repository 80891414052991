import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Header, Table } from "semantic-ui-react";
import * as unitActions from "./unit-maintenance.action";

class UnitMaintenance extends Component {
  state = {};
  componentDidMount() {
    this.props.getAllUom();
  }
  render() {
    const { unitMaintenance } = this.props;
    const { data } = unitMaintenance;
    console.log(unitMaintenance);
    return (
      <Fragment>
        <Header>List Of Units</Header>
        <Table compact sortable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>CCN</Table.HeaderCell>
              <Table.HeaderCell>Product Type</Table.HeaderCell>
              <Table.HeaderCell>Item Number</Table.HeaderCell>
              <Table.HeaderCell>Unit of Measure</Table.HeaderCell>
              <Table.HeaderCell>Unit</Table.HeaderCell>
              <Table.HeaderCell>Is 2-Level?</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data &&
              data.length > 0 &&
              data.map((data, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{data.ccn}</Table.Cell>
                  <Table.Cell>{data.productType}</Table.Cell>
                  <Table.Cell>{data.productItemNo}</Table.Cell>
                  <Table.Cell>{data.unitofMeasure}</Table.Cell>
                  <Table.Cell>{data.unit}</Table.Cell>
                  <Table.Cell>{data.isTwoLevel}</Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  unitMaintenance: state.unitMaintenance,
});

const mapDispatchToProps = (dispatch) => ({
  getAllUom: () => dispatch(unitActions.getAllUom()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitMaintenance);
