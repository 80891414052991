import React, { Component } from "react";

class History extends Component {
  state = {};
  render() {
    return <div>History</div>;
  }
}

export default History;
