import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Header, Table } from "semantic-ui-react";
import * as roleActions from "./roles-maintenance.action";

class RoleMaintenance extends Component {
  state = {};
  componentDidMount() {
    this.props.getAllRoles();
  }
  render() {
    const { roleMaintenance } = this.props;
    const { data } = roleMaintenance;
    console.log(roleMaintenance);
    return (
      <Fragment>
        <Header>List Of Roles</Header>
        <Table compact sortable striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data &&
              data.length > 0 &&
              data.map((data, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{data.id}</Table.Cell>
                  <Table.Cell>{data.description}</Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  roleMaintenance: state.rolesMaintenance
});

const mapDispatchToProps = (dispatch) => ({
  getAllRoles: () => dispatch(roleActions.getAllRoles())
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleMaintenance);
