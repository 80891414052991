import { requests } from "../request-factory";
import { mapDataToQueryString } from "../request-helpers";

export default {
  getCustomers: (applicantPsn, manufacturerPsn) =>
    requests.get(
      `customer/get-customers?${mapDataToQueryString({
        applicantPsn,
        manufacturerPsn
      })}`
    ),
  getCustomerById: (customerId) =>
    requests.get(
      `customer/get-single-customer?${mapDataToQueryString({
        customerId
      })}`
    )
};
