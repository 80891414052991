import * as types from "./reported-usage.type";
import api from "../../services/api";
import merge from "deepmerge";

const dispatchResult = (obj, type) => {
  return {
    type: type,
    data: obj,
  };
};

export const getProducts = (applicantPsn, manufacturerPsn) => {
  return async (dispatch) => {
    dispatch(dispatchResult(null, types.GET_PRODUCTS_REQUEST));
    return await api.Usage.getProducts(applicantPsn, manufacturerPsn)
      .then((result) => {
        dispatch(dispatchResult(result, types.GET_PRODUCTS_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          dispatchResult(
            result.response && result.response.data,
            types.GET_PRODUCTS_FAILURE
          )
        );
      });
  };
};

export const getProductInfo = () => {
  return async (dispatch) => {
    dispatch(dispatchResult(null, types.GET_PRODUCT_INFO_REQUEST));
    return await api.Usage.getProductInfo()
      .then((result) => {
        dispatch(dispatchResult(result, types.GET_PRODUCT_INFO_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          dispatchResult(
            result.response && result.response.data,
            types.GET_PRODUCT_INFO_FAILURE
          )
        );
      });
  };
};

export const getProductInfosByPsn = (partySiteNumber) => {
  return async (dispatch) => {
    dispatch(dispatchResult(null, types.GET_PRODUCT_INFO_BY_PSN_REQUEST));
    return await api.Usage.getProductInfosByPsn(partySiteNumber)
      .then((result) => {
        dispatch(dispatchResult(result, types.GET_PRODUCT_INFO_BY_PSN_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          dispatchResult(
            result.response && result.response.data,
            types.GET_PRODUCT_INFO_BY_PSN_FAILURE
          )
        );
      });
  };
};

export const getProductFileInfosByProdId = (prodIds,customerPsn) => {
  return async (dispatch) => {
    dispatch(dispatchResult(null, types.PRODUCT_FILE_INFO_BY_ID_REQUEST));
    return await api.Usage.getProductFileInfosByProdId(prodIds, customerPsn)
      .then((result) => {
        dispatch(dispatchResult(result, types.PRODUCT_FILE_INFO_BY_ID_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          dispatchResult(
            result.response && result.response.data,
            types.PRODUCT_FILE_INFO_BY_ID_FAILURE
          )
        );
      });
  };
};

export const getInspectionReport = (
  partySiteNumber,
  productType,
  fileNumber,
  volume,
  currentPage,
  pageSize
) => {
  return async (dispatch) => {
    dispatch(dispatchResult(null, types.GET_INSPECTION_REPORT_REQUEST));
    return await api.Usage.getInspectionReport(
      partySiteNumber,
      productType,
      fileNumber,
      volume,
      currentPage,
      pageSize
    )
      .then((result) => {
        dispatch(dispatchResult(result, types.GET_INSPECTION_REPORT_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          dispatchResult(
            result.response && result.response.data,
            types.GET_INSPECTION_REPORT_FAILURE
          )
        );
      });
  };
};

export const updateForm = (data, form) => {
  return async (dispatch) => {
    var newForm = merge(data, form);

    dispatch(dispatchResult(newForm, types.UPDATE_FORM));
  };
};

export const createUsageReport = (data) => {
  return async (dispatch) => {
    dispatch(dispatchResult(null, types.CREATE_INSPECTION_REPORT_REQUEST));
    return await api.Usage.createUsageReport(data)
      .then((result) => {
        dispatch(
          dispatchResult(result, types.CREATE_INSPECTION_REPORT_SUCCESS)
        );
        return result;
      })
      .catch((result) => {
        dispatch(
          dispatchResult(
            result.response && result.response.data,
            types.CREATE_INSPECTION_REPORT_FAILURE
          )
        );
      });
  };
};

export const getInspectionLastTransaction = (
  partySiteNumber,
  productType,
  fileNumber,
  volume,
  ccn
) => {
  return async (dispatch) => {
    dispatch(
      dispatchResult(null, types.GET_INSPECTION_LAST_TRANSACTION_REQUEST)
    );
    return await api.Usage.getLastTransaction(
      partySiteNumber,
      productType,
      fileNumber,
      volume,
      ccn
    )
      .then((result) => {
        dispatch(
          dispatchResult(result, types.GET_INSPECTION_LAST_TRANSACTION_SUCCESS)
        );
        return result;
      })
      .catch((result) => {
        dispatch(
          dispatchResult(
            result.response && result.response.data,
            types.GET_INSPECTION_LAST_TRANSACTION_FAILURE
          )
        );
      });
  };
};

export const getIfTwoLevel = (productType, ccn) => {
  return async (dispatch) => {
    dispatch(dispatchResult(null, types.GET_IF_TWO_LEVEL_REQUEST));
    return await api.Usage.getIfTwoLevel(productType, ccn)
      .then((result) => {
        dispatch(dispatchResult(result, types.GET_IF_TWO_LEVEL_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          dispatchResult(
            result.response && result.response.data,
            types.GET_IF_TWO_LEVEL_FAILURE
          )
        );
      });
  };
};

export const getDownloadReport = (data) => {
  return async (dispatch) => {
    dispatch(dispatchResult(null, types.GET_DOWNLOAD_REPORT_REQUEST));
    return await api.Usage.getDownloadReport(data)
      .then((result) => {
        dispatch(dispatchResult(result, types.GET_DOWNLOAD_REPORT_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          dispatchResult(
            result.response && result.response.data,
            types.GET_DOWNLOAD_REPORT_FAILURE
          )
        );
      });
  };
};

export const resetCreateUsageReport = () => {
  return async (dispatch) => {
    dispatch(dispatchResult(null, types.CREATE_INSPECTION_REPORT_RESET));
  };
};

export const setProductFile = (data) => {
  return async (dispatch) => {
    dispatch(dispatchResult(data, types.SET_PRODUCT_FILE));
  };
};

export const selectFile = (data, isSelected) => {
  return (dispatch) =>
    dispatch(
      dispatchResult(
        { data: data, isSelected: isSelected },
        types.SELECT_PRODUCT_FILE
      )
    );
};

export const selectAllFiles = (isSelectedAll) => {
  return (dispatch) =>
    dispatch(dispatchResult({ isSelectedAll }, types.SELECT_ALL_PRODUCT_FILE));
};

export const filterProductFile = (data) => {
  return async (dispatch) => {
    dispatch(dispatchResult(data, types.FILTER_PRODUCT_FILE));
  };
};
