export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";

export const GET_PRODUCT_INFO_REQUEST = "GET_PRODUCT_INFO_REQUEST";
export const GET_PRODUCT_INFO_FAILURE = "GET_PRODUCT_INFO_FAILURE";
export const GET_PRODUCT_INFO_SUCCESS = "GET_PRODUCT_INFO_SUCCESS";

export const GET_INSPECTION_REPORT_REQUEST = "GET_INSPECTION_REPORT_REQUEST";
export const GET_INSPECTION_REPORT_FAILURE = "GET_INSPECTION_REPORT_FAILURE";
export const GET_INSPECTION_REPORT_SUCCESS = "GET_INSPECTION_REPORT_SUCCESS";

export const USAGE_OPEN_MODAL = "USAGE_OPEN_MODAL";
export const USAGE_CLOSE_MODAL = "USAGE_CLOSE_MODAL";

export const GET_PRODUCT_INFO_BY_PSN_REQUEST =
  "GET_PRODUCT_INFO_BY_PSN_REQUEST";
export const GET_PRODUCT_INFO_BY_PSN_FAILURE =
  "GET_PRODUCT_INFO_BY_PSN_FAILURE";
export const GET_PRODUCT_INFO_BY_PSN_SUCCESS =
  "GET_PRODUCT_INFO_BY_PSN_SUCCESS";

export const PRODUCT_FILE_INFO_BY_ID_REQUEST =
  "PRODUCT_FILE_INFO_BY_ID_REQUEST";
export const PRODUCT_FILE_INFO_BY_ID_FAILURE =
  "PRODUCT_FILE_INFO_BY_ID_FAILURE";
export const PRODUCT_FILE_INFO_BY_ID_SUCCESS =
  "PRODUCT_FILE_INFO_BY_ID_SUCCESS";

export const UPDATE_FORM = "UPDATE_FORM";

export const CREATE_INSPECTION_REPORT_REQUEST =
  "CREATE_INSPECTION_REPORT_REQUEST";
export const CREATE_INSPECTION_REPORT_FAILURE =
  "CREATE_INSPECTION_REPORT_FAILURE";
export const CREATE_INSPECTION_REPORT_SUCCESS =
  "CREATE_INSPECTION_REPORT_SUCCESS";

export const GET_INSPECTION_LAST_TRANSACTION_REQUEST =
  "GET_INSPECTION_LAST_TRANSACTION_REQUEST";
export const GET_INSPECTION_LAST_TRANSACTION_FAILURE =
  "GET_INSPECTION_LAST_TRANSACTION_FAILURE";
export const GET_INSPECTION_LAST_TRANSACTION_SUCCESS =
  "GET_INSPECTION_LAST_TRANSACTION_SUCCESS";

export const GET_IF_TWO_LEVEL_REQUEST = "GET_IF_TWO_LEVEL_REQUEST";
export const GET_IF_TWO_LEVEL_FAILURE = "GET_IF_TWO_LEVEL_FAILURE";
export const GET_IF_TWO_LEVEL_SUCCESS = "GET_IF_TWO_LEVEL_SUCCESS";

export const GET_DOWNLOAD_REPORT_REQUEST = "GET_DOWNLOAD_REPORT_REQUEST";
export const GET_DOWNLOAD_REPORT_FAILURE = "GET_DOWNLOAD_REPORT_FAILURE";
export const GET_DOWNLOAD_REPORT_SUCCESS = "GET_DOWNLOAD_REPORT_SUCCESS";

export const CREATE_INSPECTION_REPORT_RESET = "CREATE_INSPECTION_REPORT_RESET";

export const SET_PRODUCT_FILE = "SET_PRODUCT_FILE";

export const SELECT_PRODUCT_FILE = "SELECT_PRODUCT_FILE";
export const SELECT_ALL_PRODUCT_FILE = "SELECT_ALL_PRODUCT_FILE";

export const FILTER_PRODUCT_FILE = "FILTER_PRODUCT_FILE";
