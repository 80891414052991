import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Main from "../views/main/main";
import Welcome from "../views/welcome/welcome";
import Auth from "../views/auth/auth";
import CallBack from "../views/callback/callback";
import locationSelection from "../views/location-selection/location-selection";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Main} />
        <Route path="/home" component={Welcome} />
        <Route path="/token/:token*" component={Auth} />
        <Route path="/callback" component={CallBack} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
