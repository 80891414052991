import * as types from "./unit-maintenance.type";

let initialState = {
  data: [],
  loading: false,
  isSuccess: false,
  data: [],
  unitOfMeasurementOptions: [],
  unitOfMeasurementData: [],
  loadingUnitOfMeasurement: false,
  isSuccessUnitOfMeasurement: false,
};

const unitMaintenanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UNIT_REQUEST:
      return {
        ...state,
        loading: true,
        isSuccess: false,
      };
    case types.UNIT_SUCCESS:
      var result = Object.assign({}, state, {
        data: action.data,
        loading: false,
        isSuccess: true,
      });
      return result;
    case types.UNIT_FAILURE:
      var result = Object.assign({}, state, {
        data: [],
        loading: false,
        isSuccess: false,
      });
      return result;
    case types.UNIT_OF_MEASUREMENT_REQUEST:
      return {
        ...state,
        loadingUom: true,
        isSuccessUom: false,
      };
    case types.UNIT_OF_MEASUREMENT_SUCCESS:
      var result = Object.assign({}, state, {
        unitOfMeasurementData: action.data,
        unitOfMeasurementOptions:
          action.data &&
          action.data.map((item) => {
            return {
              key: item.prodTypeItemNo,
              text: item.unit,
              value: item.unit,
            };
          }),
        loading: false,
        isSuccess: true,
      });
      return result;
    case types.UNIT_FAILURE:
      var result = Object.assign({}, state, {
        data: [],
        loading: false,
        isSuccess: false,
      });
      return result;
    default:
      return state;
  }
};

export default unitMaintenanceReducer;
