import "@babel/polyfill";
import "./polyfill";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routes from "./services/routes";
import { Provider } from "react-redux";
import configureStore from "./store/configure-store";
import "../src/assets/css/styles.css";
import * as serviceWorker from "./services/service-worker";
import "semantic-ui-css/semantic.min.css";

// import "semantic-ui-css/semantic.min.js";

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();
