import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import jwtDecode from "jwt-decode";
import * as actions from "./callback.action";
import { connect } from "react-redux";
import { Form } from "semantic-ui-react";

class Callback extends Component {
  state = {};

  componentDidMount() {
    var query = new URLSearchParams(this.props.location.search);
    var code = query.get("code");
    var codeVerifier = localStorage.getItem("codeVerifier");
    if (code && code !== null) {
      this.props.validateAuthenticationCode(code, codeVerifier);
    }
  }

  render() {
    const { callback } = this.props;
    const { data } = callback;
    var token = null;
    var b2cToken = null;
    var refreshToken = null;
    var userId = null;

    if (data && data.authenticatedUserToken) {
      token = data.authenticatedUserToken;
      b2cToken = data.idToken;
      refreshToken = data.refreshToken;

      const value = jwtDecode(token);
      const b2cvalue = jwtDecode(b2cToken);
      localStorage.setItem("token", JSON.stringify(token));
      localStorage.setItem("b2cToken", JSON.stringify(b2cToken));
      localStorage.setItem("userId", JSON.stringify(value.unique_name));
      localStorage.setItem("expiration", b2cvalue.exp);
      localStorage.setItem("refreshToken", JSON.stringify(refreshToken));

      userId = JSON.stringify(value.unique_name);
      var expiration = JSON.stringify(value.exp);
      var isExpired = Date.now() >= expiration * 1000;
    }

    if (userId && !isExpired) {
      return <Redirect to="/home/location-selection" />;
    } else {
      return (
        <div style={{ marginTop: "20%" }}>
          <Form loading={true}></Form>
        </div>
      );
    }
    return "";
  }
}

const mapStateToProps = (state) => ({
  callback: state.callback
});

const mapDispatchToProps = (dispatch) => ({
  validateAuthenticationCode: (authenticationCode, verifier) =>
    dispatch(actions.validateAuthenticationCode(authenticationCode, verifier))
});

export default connect(mapStateToProps, mapDispatchToProps)(Callback);
